import React, { useEffect, useState } from 'react'
import Cookies from "js-cookie";
import { useGlobalState } from '../../global';
import { useTranslation } from "react-i18next";

const CookiesPopup = () => {
    const { t } = useTranslation();

    const [acceptCookies, setAcceptCookies] = useGlobalState('cookiesConsent');

    const clickAccept = () => {
        if (Cookies.get("AFCL-analytic-cookie") == undefined && Cookies.get("AFCL-marketing-cookie") == undefined) {
            Cookies.set("AFCL-analytic-cookie", true);
            Cookies.set("AFCL-marketing-cookie", true);
        }
        Cookies.set("AFCL-cookie-consent", true);
        setAcceptCookies(true);
    }

    return (
        <div className={`row cookies-popup ${acceptCookies && 'inactive'}`}>
            <div className='col-12 col-lg-10 pb-4 pb-lg-0'>
                {t('wording_popup_cookie')}<a href='/cookies-policy' target='_blank'> <u> {t('our_cookie_policy')} </u> </a>
            </div>
            <div className='col-12 col-lg-2 text-center'>
                <button className='btn btn-black w-100' onClick={clickAccept}>
                    {t('accept')}
                </button>
            </div>
        </div>
    )
}

export default CookiesPopup