import { createGlobalState } from 'react-hooks-global-state';
import Cookies from "js-cookie";

const { useGlobalState } = createGlobalState({
    route: '',
    discountPromo: '',
    promoCode: '',
    havePro: false,
    inactiveCam: false,
    disabledPromo: false,
    publicKey: '',
    loadCampaign: false,
    businessPolicy: null,
    cookiesConsent: Cookies.get("AFCL-cookie-consent"),
});

export { useGlobalState }