import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import useRegister from '../../customHook/useRegister';
import { apiLink, coreApi } from '../../constants/DataPublic';

import { ReactComponent as Close } from '../img/close.svg';
import { useGlobalState } from '../../global'
import { useMediaQuery } from 'react-responsive';

const Checkout = (props) => {
  let { country, province, countryPhone, getCountry, getProvince, getCountryPhone } = useRegister();

  const { cart, language, userInfo } = props;
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();

  const [routePath, setRoutePath] = useGlobalState('route');
  const [requireFname, setRequireFname] = useState('');
  const [requireLname, setRequireLname] = useState('');
  const [requireID, setRequireID] = useState('');
  const [requireEmail, setRequireEmail] = useState('');
  const [requireTel, setRequireTel] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 767px)',
  });
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  const { t } = useTranslation();

  useEffect(() => {
    if (userInfo == null || Object.keys(userInfo).length === 0 || (userInfo.type === 'user' && !userInfo.isLogin)) {
      // ถ้าไม่ได้ login หรือ continue as guest
      console.log('user null', userInfo);
      sessionStorage.removeItem('UserLogin');
      navigate('/guestCheckout');
    } else if (cart == null || cart.length === 0) {
      console.log('Cart 0');
      navigate('/');
    } else {
      userInfo.paymentMethod = '';
      userInfo.paymentUrl = '';

      sessionStorage.setItem('UserLogin', JSON.stringify(userInfo));
      // saveCart();
      getUserInfo()
    }
  }, []);


  const saveCart = async (e) => {

    if (userInfo !== null && (userInfo.isLogin || userInfo.type === 'guest')) {

      let orderItem = [];
      cart.map((e) => {
        orderItem.push({
          PackageCode: e.PackageCode,
          ItemCode: e.ItemCode,
          Qty: e.Qty,
          // VisitDate: e.VisitDate === null ? null : e.VisitDate.substr(5, 2) + '/' + e.VisitDate.substr(8, 2) + '/' + e.VisitDate.substr(0, 4),
          VisitDate: e.VisitDate === null ? null : e.VisitDate,
          VisitTime: e.VisitTime,
          VisitLocation: e.VisitLocation,
        });
      });

      let bodytext = {
        ConfirmationNo: userInfo.confirmationNo,
        ChannelCode: userInfo.channelCode,
        UserId: userInfo.userId,
        UserCode: userInfo.userCode,
        PromotionCode: '',
        OrderItems: orderItem,
        Prefix: routePath.replace('/', '')
      };

      // Email: userInfo.email,
      //   FirstName: userInfo.fName,
      //   LastName: userInfo.lName,
      //   PhoneNo: userInfo.phoneNo,
      //   IDCardNo: userInfo.idCardNo,
      //   telCode: userInfo.telCode,
      //   DeviceType:"",
      //   PushKey:"",
      //   DeviceKey:"",

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'accept-language': language },
        body: JSON.stringify(bodytext),
      };

      await fetch(apiLink + '/afcl/Package/SaveOrder/V2', requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          const items = data;
          let pk = items['Result']['InvalidPk'].filter((a) => {
            return cart.find((b) => {
              return a.PackageCode === b.PackageCode;
            });
          });
          if (pk.length > 0) {
            setShowAlert(true)
          } else {
            if (items['Success']) {
              console.log('Success: ', items['Success']);
              userInfo.confirmationNo = items['Result'].ConfirmationNo;
              sessionStorage.setItem('UserLogin', JSON.stringify(userInfo));
              getUserInfo(items['Result'].ConfirmationNo);
              getCountry();
              getCountryPhone();
            } else if (items['fail']) {
              alert('Promo invalid')
            }
          }

        })
        .catch((error) => {
          console.log('Error: ', error);
          // alert('Error: API /afcl/Package/SaveOrder ', error)
        });
    } else {
      navigate('/signin');
    }
  };

  const getUserInfo = async (confirmationNo) => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'accept-language': language },
      // body: JSON.stringify({
      //   ConfirmationNo: confirmationNo,
      //   UserCode: userInfo.userCode,
      // }),
    };

    await fetch(apiLink + '/afcl/Package/CheckOutV2/' + userInfo.userCode + '?confirmationNo=' + confirmationNo, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const items = data;

        userInfo.fName = items['Result']['UserInformation'].FirstName;
        userInfo.lName = items['Result']['UserInformation'].LastName;
        userInfo.email = items['Result']['UserInformation'].Email;
        userInfo.phoneNo = items['Result']['UserInformation'].PhoneNo;
        userInfo.idCardNo = items['Result']['UserInformation'].IDCardNo;
        userInfo.country = items['Result']['UserInformation'].Country;
        userInfo.province = items['Result']['UserInformation'].Province;
        userInfo.countryId = items['Result']['UserInformation'].CountryId;
        userInfo.provinceId = items['Result']['UserInformation'].ProvinceId;
        userInfo.PhoneCode = items['Result']['UserInformation'].PhoneCode;

        if (userInfo.countryId == null) {
          userInfo.telCode = 66;
        }

        sessionStorage.setItem('UserLogin', JSON.stringify(userInfo));

        if (userInfo.countryId != null) {
          getProvince(userInfo.countryId);
        }

        getCountry();
        getCountryPhone();

        items['Result']['PaymentMethods'].sort((a, b) => (a > b ? 1 : -1));

        setPaymentMethod(items['Result']['PaymentMethods']);

        console.log("userInfo.telCode", items)
        console.log("userInfouserInfo.countryId", userInfo)
      })
      .catch((error) => {
        console.log('Error: ', error);
        // alert('Error: API /afcl/Package/CheckOut ', error)
      });
  };

  const checkInfo = (e) => {
    e.preventDefault();

    if (userInfo.fName === '') {
      setRequireFname('warn_firstname');
    } else setRequireFname('');

    if (userInfo.lName === '') {
      setRequireLname('warn_lastname');
    } else setRequireLname('');

    // if (userInfo.idCardNo === '') {
    //   setRequireID('warn_id');
    // } else setRequireID('');

    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const validMail = regex.test(userInfo.email);
    if (userInfo.email === '') {
      setRequireEmail('warn_email');
    } else if (!validMail) {
      setRequireEmail('invalid_email');
    } else setRequireEmail('');

    if (userInfo.phoneNo === '') {
      setRequireTel('warn_phone');
    } else setRequireTel('');

    // && userInfo.idCardNo 
    if (userInfo.fName != '' && userInfo.lName != '' != '' && userInfo.email != '' && validMail && userInfo.phoneNo != '') {
      updateVisitor();
    }
  };

  const updateVisitor = async () => {
    setEdit(false);
    props.setEditGuest(false);
    console.log('UpdateVisitor :', userInfo);
    sessionStorage.setItem('UserLogin', JSON.stringify(userInfo));
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'accept-language': language },
      body: JSON.stringify({
        UserId: userInfo.userId,
        UserCode: userInfo.userCode,
        PhoneNo: userInfo.phoneNo,
        Password: '',
        CountryId: userInfo.countryId,
        ProvinceId: userInfo.provinceId,
        HasSubscribeOffer: false,
        FirstName: userInfo.fName,
        LastName: userInfo.lName,
        Email: userInfo.email,
        IDCardNo: userInfo.idCardNo,
      }),
    };

    await fetch(coreApi + '/afcl/Account/visitor/update/profile', requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const items = data;
        console.log('UpdateVisitor result :', items);
        if (items['Success'] === true) {
          console.log('UpdateVisitor Success = true');
          setErrMessage('');
          getUserInfo(userInfo.confirmationNo);
        } else {
          console.log('UpdateVisitor Success = false');
          setErrMessage(items['Message']);
          setEdit(true);
          props.setEditGuest(true);
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        // alert('Error: API /afcl/Account/visitor/update/profile ', error)
      });
  };

  /** Register Success. **/
  const [showPopup, setShowPopup] = useState(false);
  const CloseSignupSuccess = () => setShowPopup(false);
  const ShowSignupSuccess = () => setShowPopup(true);

  return (
    <div className="col-main">
      <div className="info-wrapper py-4 px-3 px-lg-0">
        <div className="row mb-4">
          <div className="col-lg-12">
            <h2 className="font-30 text-uppercase mb-5 lh-1">
              {userInfo.type === 'guest' ? t('visitor_information') : t('customer_information')}&nbsp;
              {!edit && (
                <a href="#" className="font-12 text-capitalize weight-500" onClick={() => { setEdit(true); props.setEditGuest(true) }}>
                  {t('edit')}
                </a>
              )}
              {edit && (
                <a href="#" className="font-12 text-capitalize weight-500" onClick={checkInfo}>
                  {t('save')}
                </a>
              )}
            </h2>
          </div>
        </div>

        {edit && (
          <form>
            <div className="row mb-3">
              <span className="text-danger">{errMessage}</span>
              <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <label className="font-12">{t('firstname')} :</label>
                <input type="text" id="FirstName" className="form-control" defaultValue={userInfo.fName} onChange={(e) => (userInfo.fName = e.target.value)} />
                <span className="text-danger">{t(requireFname)}</span>
              </div>

              <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <label className="font-12">{t('lastname')} :</label>
                <input type="text" className="form-control" placeholder="" defaultValue={userInfo.lName} onChange={(e) => (userInfo.lName = e.target.value)} />
                <span className="text-danger">{t(requireLname)}</span>
              </div>

              <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <label className="font-12">{t('email')} :</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder=""
                  defaultValue={userInfo.email}
                  onChange={(e) => (userInfo.email = e.target.value)}
                  disabled={userInfo.type === 'user' || userInfo.isLogin}
                />
                <span className="text-danger">{t(requireEmail)}</span>
              </div>

              <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <label className="font-12">{t('phone_number')} :</label>

                <div className="row">
                  <div className="col-3 pb-3 pb-lg-0">
                    <select
                      className="form-control"
                      id="telCode"
                      defaultValue={userInfo.type === 'guest' ? userInfo.telCode : userInfo.countryId}
                      onChange={(e) => {
                        userInfo.type === 'guest' ? (userInfo.telCode = e.target.value) : (userInfo.countryId = e.target.value);
                        userInfo.countryId = e.target.value;
                        getProvince(e.target.value);
                        console.log('userInfo', userInfo);
                      }}
                    >
                      <option key="0" value="0">
                        -
                      </option>
                      {countryPhone.map((e, key) => {
                        return (
                          <option key={e.CountryId} value={e.CountryId} selected={userInfo.type === 'guest' ? userInfo.telCode == e.CountryId : userInfo.countryId == e.CountryId}>
                            {e.PhoneCode}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-9">
                    <input
                      type="Tel"
                      className="form-control"
                      placeholder=""
                      defaultValue={userInfo.phoneNo}
                      maxLength="10"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        userInfo.phoneNo = e.target.value;
                      }}
                    />
                  </div>
                </div>
                <span className="text-danger">{t(requireTel)}</span>
              </div>

              {/* <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <label className="font-12">{t('id_number')} :</label>
                <input maxLength="20" type="text" className="form-control" placeholder="" defaultValue={userInfo.idCardNo} onChange={(e) => (userInfo.idCardNo = e.target.value)} />
                <span className="text-danger">{t(requireID)}</span>
              </div> */}

              <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <label htmlFor="inputCountry" className="font-12">
                  {t('country')} :
                </label>
                <select
                  id="inputCountry"
                  className="form-control"
                  defaultValue={userInfo.countryId}
                  onChange={(e) => {
                    getProvince(e.target.value);
                    userInfo.countryId = e.target.value;
                    userInfo.telCode = e.target.value;
                    userInfo.provinceId = '';
                    userInfo.province = '';
                    console.log('userInfo', userInfo);
                  }}
                >
                  <option key="0" value="0">
                    {t('select_country')}
                  </option>
                  {country.map((e, key) => {
                    return (
                      <option key={e.CountryId} value={e.CountryId} selected={userInfo.type === 'guest' ? userInfo.telCode == e.CountryId : userInfo.countryId == e.CountryId}>
                        {e.CountryName}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="form-group col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                <label htmlFor="inputProvince" className="font-12">
                  {t('state')} :
                </label>
                <select id="inputProvince" className="form-control" defaultValue={userInfo.provinceId} onChange={(e) => (userInfo.provinceId = e.target.value)}>
                  <option key="0" value="0">
                    {t('select_province')}
                  </option>
                  {province.map((e, key) => {
                    return (
                      <option key={e.CityId} value={e.CityId} selected={userInfo.provinceId == e.CityId}>
                        {e.CityName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </form>
        )}
        {!edit && (
          <div className="row mb-4">
            <div className="col-lg-6">
              <div className="row mb-4">
                <div className="col-lg-12">
                  <p className="color-grey font-12 mb-0">{t('firstname')} : </p>
                  <p className="weight-700 mb-0">{userInfo.fName}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-4">
                <div className="col-lg-12">
                  <p className="color-grey font-12 mb-0">{t('lastname')} : </p>
                  <p className="weight-700 mb-0">{userInfo.lName}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-4">
                <div className="col-lg-12">
                  <p className="color-grey font-12 mb-0">{t('email')} : </p>
                  <p className="weight-700 mb-0 ">{userInfo.email}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-4">
                <div className="col-lg-12">
                  <p className="color-grey font-12 mb-0">{t('phone_number')} : </p>

                  {userInfo.type === 'guest' ? (
                    <p className="weight-700 mb-0 ">
                      {userInfo.PhoneCode != undefined && userInfo.PhoneCode != '' ? '(+' + userInfo.PhoneCode + ')' : ''}
                      {userInfo.phoneNo}
                    </p>
                  ) : (
                    <p className="weight-700 mb-0 ">
                      {userInfo.PhoneCode != undefined && userInfo.PhoneCode != '' ? '(+' + userInfo.PhoneCode + ')' : ''}
                      {userInfo.phoneNo}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="col-lg-6">
              <div className="row mb-4">
                <div className="col-lg-12">
                  <p className="color-grey font-12 mb-0">{t('id_number')} : </p>
                  <p className="weight-700 mb-0 ">{userInfo.idCardNo}</p>
                </div>
              </div>
            </div> */}

            <div className="col-lg-6">
              <div className="row mb-4">
                <div className="col-lg-12">
                  <p className="color-grey font-12 mb-0">{t('country')} : </p>
                  <p className="weight-700 mb-0 ">{userInfo.country}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-4">
                <div className="col-lg-12">
                  <p className="color-grey font-12 mb-0">{t('state')} : </p>
                  <p className="weight-700 mb-0 ">{userInfo.province}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <div className="box-payment ">
          <div className="card-body my-3 mx-3">
            <h3 className="font-30 text-uppercase lh-1 mb-4 weight-700">{t('select_payment')}</h3>

            <ul className="list-unstyled">
              {routePath.toLocaleLowerCase() == '/16two' && paymentMethod.length > 0 && (
                <li
                  className="my-4 mx-3"
                  onClick={() => {
                    const radioBtn = document.getElementById('Installment');
                    radioBtn.checked = true;
                    userInfo.paymentMethod = 'Installment';
                    userInfo.paymentUrl = '';
                    userInfo.totalPrice = '';
                    sessionStorage.setItem('UserLogin', JSON.stringify(userInfo));
                  }}
                >
                  <div className="form-check box-checkpayment row">
                    <div className="row" style={{ alignItems: 'center' }}>
                      <div className="col-12">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="payment_method"
                          id="Installment"
                          value="Installment"
                          onChange={() => {
                            userInfo.paymentMethod = 'Installment';
                            userInfo.paymentUrl = '';
                            userInfo.totalPrice = '';
                            sessionStorage.setItem('UserLogin', JSON.stringify(userInfo));
                          }}
                        />
                        <div style={{ width: isDesktopOrLaptop ? '270px' : '204px', borderRadius: '20px', display: 'flex', justifyContent: 'center', background: '#cbcbcb', height: '25px', alignItems: 'center' }}>
                          <img src="https://gsastorages.blob.core.windows.net/afcl/CREDIT.png" height="60%" style={{ marginRight: '8px' }} /> &nbsp;
                          <label className="form-check-label">
                            {' '}
                            <>{t('visa')} ({t('Installment')})</>{' '}
                          </label>
                        </div>
                      </div>
                      <div className="col-6" style={{ display: 'flex', justifyContent: 'right' }}>
                      </div>
                    </div>
                  </div>
                </li>
              )}

              {paymentMethod.map((e, index) => {
                return (
                  <li
                    className="my-4 mx-3"
                    onClick={() => {
                      const radioBtn = document.getElementById(e.MethodName);
                      radioBtn.checked = true;
                      userInfo.paymentMethod = e.MethodName;
                      // userInfo.paymentUrl = e.PaymentUrl;
                      // userInfo.totalPrice = e.TotalPrice;
                      sessionStorage.setItem('UserLogin', JSON.stringify(userInfo));
                    }}
                  >
                    <div className="form-check box-checkpayment row">
                      <div className="row" style={{ alignItems: 'center' }}>
                        <div className="col-12">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="payment_method"
                            id={e.MethodName}
                            value={e.MethodName}
                            onChange={() => {
                              userInfo.paymentMethod = e.MethodName;
                              // userInfo.paymentUrl = e.PaymentUrl;
                              // userInfo.totalPrice = e.TotalPrice;
                              sessionStorage.setItem('UserLogin', JSON.stringify(userInfo));
                            }}
                          />
                          <div
                            style={{
                              width: index == 0 ? '180px' : '160px',
                              borderRadius: '20px',
                              display: 'flex',
                              justifyContent: 'center',
                              background: '#cbcbcb',
                              height: '25px',
                              alignItems: 'center',
                            }}
                          >
                            <img src={e.IconUrl} height="60%" style={{ marginRight: '8px' }} /> &nbsp;
                            <label className="form-check-label"> {index == 0 ? <>{t('visa')}</> : <>{t('bank')}</>} </label>
                            {e.MethodName}
                          </div>
                        </div>
                        <div className="col-6" style={{ display: 'flex', justifyContent: 'right' }}>
                          <img src={e.MethodLogoUrl} className="payment-2c2p" />
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>

            <p className="font-12 weight-500 mb-0" style={{ color: '#F71980' }}>
              {'*' + t('indicate_required')}
            </p>
          </div>
        </div> */}
      </div>
      <Modal show={showAlert} className="col-12 cart-placeorder">
        <a href="#" className="close">
          <Close className="popup-close" onClick={() => setShowAlert(false)} />
        </a>
        <h5 className="text-center text-danger py-5">{t('package_invalid')}</h5>
      </Modal>
    </div>
  );
};
export default Checkout;
