import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Countdown, { zeroPad } from "react-countdown";
import { apiLink } from "../constants/DataPublic";
import { ReactComponent as Close } from "../component/img/close.svg";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive'
import { useGlobalState } from '../global';

const PaymentOmise = (props) => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 990px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 990px)' })

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    let myCart = sessionStorage.getItem('MyCart')
    myCart = JSON.parse(myCart)

    let Promo = sessionStorage.getItem('Promo')
    Promo = JSON.parse(Promo)

    let paymentHis = sessionStorage.getItem('paymentHis')
    paymentHis = JSON.parse(paymentHis)

    const params = new URLSearchParams(window.location.search)

    const { state } = useLocation();

    const { language } = props
    const [publicKey, setPublicKey] = useGlobalState('publicKey')
    const [webPaymentUrl, setWebPaymentUrl] = useState("")
    const [invoiceNo, setInvoiceNo] = useState("")
    const [total, setTotal] = useState(0)
    const [countryList, setCountryList] = useState([])
    const [qrExpired, setQrExpired] = useState(600000)
    const [paymentSuccess, setPaymentSuccess] = useState(null)
    const [showAlert, setShowAlert] = useState(false);
    const [showSelectmethod, setShowSelectMethod] = useState(false);
    const [loadingApi, setLoadingApi] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [paymentList, setPaymentList] = useState([]);
    const [paymentType, setPaymentType] = useState(null);
    const [expiryShow, setExpiryShow] = useState('');
    const [cooldown, setCooldown] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [currentPayment, setCurrentPayment] = useState({
        paymentName: "",
        index: 0,
        paymentType: "credit_card"
    });
    const [cardInfo, setCardInfo] = useState({
        name: '',
        number: '',
        security_code: '',
        expiration_month: '',
        expiration_year: '',
        country: 'TH'
    });

    const [requireName, setRequireName] = useState('');
    const [requireNumber, setRequireNumber] = useState('');
    const [requireSecurity, setRequireSecuriry] = useState('');
    const [requireExpireMonth, setRequireExpireMounth] = useState('');
    const [requirerExpireYear, setRequireExpireYear] = useState('');
    const [requireCountry, setRequireCountry] = useState('');
    const [requireOmise, setRequireOmise] = useState('');

    let [OmiseCard, setOmiseCard] = useState();
    let [Omise, setOmise] = useState();


    const { t } = useTranslation();
    const navigate = useNavigate()

    useEffect(() => {

        console.log("Payment: ", user);
        console.log("language: ", language);
        console.log("myCart: ", myCart);
        if (myCart == null || myCart == undefined || myCart.length == 0 || user == null || user == undefined) {
            navigate("/")
        } else {
            if (language) {
                let confirm = params.get('ConfirmationNo')
                let userCode = params.get('UserCode')
                if (user != null && user != undefined) {
                    setTotal(user.totalPrice)
                    if (confirm && userCode) {
                        navigate('/paymentProcess')
                    } else {
                        HandleLoadScript()
                        setTotal(user.totalPrice)
                        getInvoiceNumber()
                        getOmisePayment()
                        getCountry()
                        console.log("getMobileOS: ", getMobileOS());
                    }
                }
            }
        }
    }, [language])

    const getMobileOS = () => {
        const ua = navigator.userAgent
        if (/android/i.test(ua)) {
            return "Android"
        }
        else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
            return "iOS"
        }
        return "Other"
    }


    useEffect(() => {
        if (currentPayment.paymentType === 'promptpay') {
            omiseCardHandleSuccess()
        }
    }, [currentPayment])

    const HandleLoadScript = () => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://cdn.omise.co/omise.js";
        script.async = true;
        script.onload = () => OmiseLoaded();
        document.body.appendChild(script);
    }

    const OmiseLoaded = async () => {
        setOmise(window.Omise)
        setOmiseCard(window.OmiseCard)
    }

    useEffect(() => {
        if (Omise && OmiseCard) {
            getPublicKey()
            console.log("publicKeypublicKey: ", publicKey);
            Omise.setPublicKey(publicKey)
            OmiseCard.configure({
                publicKey: publicKey,
                currency: 'thb',
                frameLabel: 'AFCL',
                submitLabel: 'Pay Now',
                buttonLabel: 'Pay with Omise',
            })
        }
    }, [publicKey, Omise, OmiseCard])

    const getPublicKey = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "accept-language": language, 'Content-Type': 'application/json', },
        };
        // /afcl/Omise/PublicKey
        await fetch(apiLink + '/afcl/Omise/PublicKey', requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data["Success"]) {
                    const items = data["Result"];
                    setPublicKey(items["VariableValues"])

                } else {
                    console.log("bad request", data["Result"])
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
                // alert('Error: API ', user.paymentUrl , error)
            });
    }



    const CreditCardConfigure = (e) => {
        OmiseCard.configure({
            defaultPaymentMethod: e == 'ewallet' ? 'alipay' : e,
            otherPaymentMethods: e == 'ewallet' ? ['truemoney', 'shopeepay', 'alipay', 'rabbit_linepay', 'googlepay'] : [],
        })
        OmiseCard.configureButton('#' + e)
        OmiseCard.attach()
    }

    const omiseCardHandle = async (e) => {
        OmiseCard.open({
            amount: total,
            phone_number: "",
            onCreateTokenSuccess: (token) => {
                console.log("nonce", token)
                console.log("OmiseCard", OmiseCard)
                omiseCardHandleSuccess(token, e);
            },
        })

    }

    const handlePayment = (e) => {
        // e.preventDefault()
        CreditCardConfigure(e)
        omiseCardHandle(e)
    }

    const getCountry = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "accept-language": language, 'Content-Type': 'application/json', },
        };
        // /afcl/Package/CountryIsoLookup/{culture}
        await fetch(apiLink + '/afcl/Package/CountryIsoLookup/' + language, requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data["Success"]) {
                    const items = data["Result"];
                    setCountryList(items)
                    console.log("Country", items)
                    // setPaymentList(items)
                } else {
                    console.log("bad request", data["Result"])
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
                // alert('Error: API ', user.paymentUrl , error)
            });
    }


    const omiseCardHandleSuccess = async (nonce, type) => {
        setLoadingApi(true)
        if (paymentType === 'truemoney') {
            navigate('/paymentOmise/trueMoney')
        } else {
            console.log("omiseCardHandleSuccess", paymentType)
            console.log("Token", nonce)
            let path, valid, payload
            if (currentPayment.paymentType !== 'credit_card') {
                // /afcl/Package/Omise/InternetBanking
                path = '/afcl/Package/Omise/InternetBanking'
                valid = paymentType || currentPayment.paymentType === 'promptpay' ? true : false
                payload = JSON.stringify({
                    "ConfirmationNo": user.confirmationNo,
                    "UserCode": user.userCode,
                    "paymentType": currentPayment.paymentType === 'promptpay' ? 'promptpay' : paymentType,
                    "PhoneNo": "",
                    "InvoiceNumber": user.InvoiceNumber,
                    "PlatformType": ""
                })
            }
            if (currentPayment.paymentType === 'credit_card') {
                valid = true
                // /afcl/Package/Omise/CreditCard
                path = '/afcl/Package/Omise/CreditCard'
                payload = JSON.stringify({
                    "ConfirmationNo": user.confirmationNo,
                    "UserCode": user.userCode,
                    "CardToken": nonce,
                    "Amount": user.totalPrice,
                    "InvoiceNumber": user.InvoiceNumber
                })
            }
            if (currentPayment.paymentType === 'mobile_banking') {
                valid = paymentType ? true : false
                // /afcl/Package/Omise/CreditCard
                path = '/afcl/Package/Omise/MobileBanking'
                payload = JSON.stringify({
                    "UserCode": user.userCode,
                    "ConfirmationNo": user.confirmationNo,
                    "PaymentType": paymentType,
                    "InvoiceNumber": user.InvoiceNumber,
                    "PhoneNo": "",
                    "PlatformType": getMobileOS().toLocaleUpperCase()
                })
            }

            if (valid) {
                const requestOptions = {
                    method: "POST",
                    headers: { "accept-language": language, 'Content-Type': 'application/json', },
                    body: payload,
                };
                await fetch(apiLink + path, requestOptions).
                    then(function (response) {
                        return response.json();
                    })
                    .then(function (data) {
                        if (data["Success"]) {
                            const items = data["Result"];
                            console.log("items['PaymentType']", items['PaymentType'])
                            setLoadingApi(false)
                            if (items['PaymentType'] === 'promptpay') {
                                setWebPaymentUrl(items["WebPaymentUrl"])
                                setShowAlert(true)
                            } else {
                                window.location.href = items["WebPaymentUrl"];
                            }
                        } else {
                            setLoadingApi(false)
                            console.log("bad request", data["Result"])
                        }
                    })
            } else {
                setShowSelectMethod(true)
                setLoadingApi(false)
            }
        }

    }


    const getOmisePayment = async () => {
        // /afcl/Omise
        const requestOptions = {
            method: "GET",
            headers: { "accept-language": language, 'Content-Type': 'application/json', },
        };
        await fetch(apiLink + '/afcl/Omise', requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data["Success"]) {
                    const items = data["Result"];
                    setPaymentList(items)
                } else {
                    console.log("bad request", data["Result"])
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
                // alert('Error: API ', user.paymentUrl , error)
            });
    }

    const getInvoiceNumber = async () => {
        // /afcl/Package/PayOmise
        const requestOptions = {
            method: "POST",
            headers: { "accept-language": language, 'Content-Type': 'application/json', },
            body: JSON.stringify({
                "ConfirmationNo": user.confirmationNo,
                "UserCode": user.userCode,
            }),
        };
        await fetch(apiLink + '/afcl/Package/PayOmise', requestOptions).
            then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data["Success"]) {
                    const items = data["Result"];
                    user.InvoiceNumber = items["InvoiceNumber"];
                    sessionStorage.setItem('UserLogin', JSON.stringify(user));
                    setInvoiceNo(items["InvoiceNumber"])
                } else {
                    navigate("/ticket/" + myCart[0].PackageCode + "/5")
                    console.log("bad request", data["Result"])
                }
            })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (cardInfo.name === '') {
            setRequireName('warn_card_name');
        } else setRequireName('');

        if (cardInfo.number === '') {
            setRequireNumber('warn_card_number');
        } else setRequireNumber('');

        if (cardInfo.expiration_month === '') {
            setRequireExpireMounth('warn_card_expire_month');
        } else setRequireExpireMounth('');

        if (cardInfo.expiration_year === '') {
            setRequireExpireYear('warn_card_expire_year');
        } else setRequireExpireYear('');

        if (cardInfo.security_code === '') {
            setRequireSecuriry('warn_card_security');
        } else setRequireSecuriry('');

        if (cardInfo.country === '') {
            setRequireCountry('warn_card_country');
        } else setRequireCountry('');

        if (cardInfo.name != '' && cardInfo.number != '' && cardInfo.expiration_month != '' && cardInfo.expiration_year && cardInfo.security_code != '' && cardInfo.country != '') {
            try {
                setLoadingApi(true)
                // const token = await createTokenPromise('card', cardInfo);
                Omise.createToken("card", cardInfo,
                    function (statusCode, response) {
                        omiseCardHandleSuccess(response['id'], 'card')
                        console.log(response['id'])
                    });

            } catch (error) {
                setLoadingApi(false)
                setRequireOmise('**' + error.message)
                console.log("requireNumber: ", requireNumber);
                console.log("Error: ", error);
            }
        }
    };

    // const createMobileSource = async () => {
    //     Omise.createSource(paymentType, {
    //         amount: total,
    //         currency: "THB",
    //         platform_type: "IOS"
    //     }, function (statusCode, response) {
    //         createSourceCharge(response['id'])
    //         console.log(response['id'])
    //     });
    // }

    // const createSourceCharge = async (source) => {
    //     setLoadingApi(true)
    //     const requestOptions = {
    //         method: "POST",
    //         headers: { "accept-language": language, 'Content-Type': 'application/json', },
    //         body: JSON.stringify({
    //             amount: total,
    //             currency: 'THB',
    //             source: source,
    //             ConfirmationNo: user.confirmationNo,
    //             userCode: user.userCode,
    //             apiLink: apiLink,
    //         }),
    //     };
    //     await fetch('http://183.88.237.124:8888/create-mobile-charge',requestOptions).
    //         then(function (response) {
    //             return response.json();
    //         })
    //         .then(function (data) {
    //             if (data["Success"]) {
    //                 setLoadingApi(false)
    //                 const items = data["WebPaymentUrl"];
    //                 window.location.href = items;
    //             } else {
    //                 setLoadingApi(false)
    //                 navigate("/ticket/" + myCart[0].PackageCode + "/5")
    //                 console.log("bad request", data["Result"])
    //             }
    //         })
    // }


    const handleBlur = (e) => {
        console.log("e", e)
        if (!e.target.value.includes("/") && e.target.value.length === 4) {
            const inputValue = e.target.value;
            const updatedValue = inputValue.slice(0, 2) + '/' + inputValue.slice(2);
            cardInfo.expiration_month = inputValue.slice(0, 2)
            cardInfo.expiration_year = inputValue.slice(2)
            setExpiryShow(updatedValue);
        }

        if (e.target.value.includes("/")) {
            const inputValue = e.target.value;
            let expireyear = inputValue.slice(2)
            // const updatedValue = inputValue.slice(0, 2) + '/' + inputValue.slice(2);
            cardInfo.expiration_month = inputValue.slice(0, 2)
            cardInfo.expiration_year = expireyear.replace("/", "")
            // setExpiryShow(updatedValue);
        }
    }

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state
            return <span></span>
        } else {
            // Render a countdown
            return (
                <span>
                    {zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
            );
        }
    };

    const checkQrStatus = async (e) => {
        // /afcl/Package/PayOmise
        const requestOptions = {
            method: "GET",
            headers: { "accept-language": language, 'Content-Type': 'application/json', }
        };
        await fetch(apiLink + '/afcl/Package/Omise/Inquiry' + '?ConfirmationNo=' + user.confirmationNo + '&UserCode=' + user.userCode, requestOptions).
            then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data["Success"]) {
                    const items = data["Result"];
                    if (items['ResponseCode'] === "successful") {
                        sessionStorage.removeItem("MyCart")
                        navigate("/done", { state: { confirmationNo: user.confirmationNo, TagHtml: items["TagHtml"] } })
                    } else if (items['ResponseCode'] === "failed") {
                        navigate("/paymentfail", { state: { ResponseDetail: items["ResponseDetail"], } })
                    } else if (items['ResponseCode'] === "expired") {

                    } else if (items['ResponseCode'] === "pending") {

                    } else if (items['ResponseCode'] === "reversed") {

                    } else {
                        console.log("error")
                    }
                } else {
                    console.log("bad request", data["Result"])
                }
            })
    }

    useEffect(() => {
        if (cooldown) {
            const interval = setInterval(() => {
                checkQrStatus()
            }, 5000)
            return () => clearInterval(interval)
        }
    }, [cooldown])

    useEffect(() => {
        if (showAlert) {
            const interval = setInterval(() => {
                setShowButton(true)
            }, 5000)
            return () => clearInterval(interval)
        }
    }, [showAlert])

    const onCloseModal = () => {
        setCooldown(false)
        setShowButton(false)
        setShowAlert(false)
    }


    return (
        <div className="page homepage" style={{ minHeight: isTabletOrMobile ? 'auto' : 'calc(100vh - 183px)' }}>
            <div className="section">
                <div className="container">
                    {paymentList.length !== 0 && user != null ? (
                        ''
                    ) : (
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    )}

                    {loadingApi && (
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    )}

                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-11" style={{ margin: '0 auto' }}>
                        <div className="row mb-4">
                            <div className="col-lg-12">
                                <h2 className="font-30 mb-5 lh-1" style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: '#00BFEA'
                                }}>
                                    {t('checkout')}
                                </h2>
                            </div>
                            <div className="col-lg-12">
                                <h5 className="font-16 lh-1" style={{
                                    fontWeight: 600,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {invoiceNo}
                                </h5>
                                <h5 className="font-16 mb-3 lh-1" style={{
                                    fontWeight: 600,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                                </h5>
                            </div>
                        </div>
                        {paymentList.length !== 0 && (
                            <div className="row">
                                <div className="col-sm-6">
                                    <h2 className="font-30 text-uppercase mb-5 lh-1" style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        {t('select_payment_omi') + '*'}
                                    </h2>
                                    <form>
                                        <div className="row d-flex">
                                            {paymentList.map((item, index) => {
                                                return (
                                                    <>
                                                        {getMobileOS().toLocaleLowerCase() === 'other' ? (
                                                            <>
                                                                {item.PaymentType !== 'mobile_banking' && (
                                                                    <a key={item.Id} href={isTabletOrMobile ? '#' + currentPayment.paymentType : '##'} className="col-sm-6" style={{ paddingBottom: '20px', color: '#000' }}>
                                                                        <div id="credit_card" className="card" style={{ cursor: 'pointer', background: index === currentPayment.index ? '#79E7FF' : '', textAlign: 'center' }}
                                                                            onClick={() => { setCurrentPayment({ paymentName: item.PaymentHeaderName, index: index, paymentType: item.PaymentType }); setPaymentType(0); }}
                                                                        // onClick={() => handlePayment('credit_card')}
                                                                        >
                                                                            <div className="card-body">
                                                                                <p className="card-text">
                                                                                    <img src={item.Picture} width={item.PaymentType === 'credit_card' ? 113 : item.PaymentType === 'internet_banking' || item.PaymentType === 'mobile_banking' ? 95 : item.PaymentType === 'promptpay' ? 60 : 70} />
                                                                                </p>
                                                                                <p className="card-text">{item.PaymentHeaderName}</p>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <a key={item.Id} href={isTabletOrMobile ? '#' + currentPayment.paymentType : '##'} className="col-sm-6" style={{ paddingBottom: '20px', color: '#000' }}>
                                                                    <div id="credit_card" className="card" style={{ cursor: 'pointer', background: index === currentPayment.index ? '#79E7FF' : '', textAlign: 'center' }}
                                                                        onClick={() => { setCurrentPayment({ paymentName: item.PaymentHeaderName, index: index, paymentType: item.PaymentType }); setPaymentType(0); }}
                                                                    // onClick={() => handlePayment('credit_card')}
                                                                    >
                                                                        <div className="card-body">
                                                                            <p className="card-text">
                                                                                <img src={item.Picture} width={item.PaymentType === 'credit_card' ? 113 : item.PaymentType === 'internet_banking' || item.PaymentType === 'mobile_banking' ? 95 : item.PaymentType === 'promptpay' ? 60 : 70} />
                                                                            </p>
                                                                            <p className="card-text">{item.PaymentHeaderName}</p>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </>
                                                        )}

                                                    </>
                                                )
                                            })}
                                        </div>
                                    </form>
                                </div>

                                <div className="col-sm-6">

                                    {/* for Credit Card */}
                                    {currentPayment.paymentType === 'credit_card' && (
                                        <div id={currentPayment.paymentType} className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div className="py-5" style={{ paddingLeft: isTabletOrMobile ? '0px' : '40px' }}>
                                                <h2 className="font-30 mb-1 lh-1">{t('credit_debit_card')}</h2>
                                                <p className="color-grey mb-4">Secured by Omise</p>

                                                <form>
                                                    <div className="mb-3 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                                                        <p className="mb-1 px-2" style={{ color: '#0E315C', fontWeight: '500' }}>{t('card_number')} *</p>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength="16"
                                                            name="cardInfo_number"
                                                            placeholder={t('card_number') + '*'}
                                                            style={{ border: requireNumber !== '' ? '1px solid #ca4d4d' : '' }}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => cardInfo.number = e.target.value} />
                                                        <span className="text-danger">{t(requireNumber)}</span>
                                                    </div>

                                                    <div className="mb-3 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                                                        <p className="mb-1 px-2" style={{ color: '#0E315C', fontWeight: '500' }}>{t('card_name')} *</p>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="cardInfo_name"
                                                            placeholder={t('card_name') + '*'}
                                                            style={{ border: requireName !== '' ? '1px solid #ca4d4d' : '' }}
                                                            onKeyPress={(event) => {
                                                                if (!/[a-zA-Z]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => cardInfo.name = e.target.value} />
                                                        <span className="text-danger">{t(requireName)}</span>
                                                    </div>
                                                    <div className="mb-2 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="mb-1 px-2" style={{ color: '#0E315C', fontWeight: '500' }}>{t('expiry_date')} *</p>
                                                                <input
                                                                    value={expiryShow}
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="cardInfo_expiry"
                                                                    maxLength="5"
                                                                    placeholder={t('expiry_date') + '*'}
                                                                    style={{ border: requireExpireMonth !== '' ? '1px solid #ca4d4d' : '' }}
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onChange={(e) => { setExpiryShow(e.target.value); handleBlur(e) }}
                                                                />
                                                                <span className="text-danger">{t(requireExpireMonth)}</span>
                                                            </div>

                                                            <div className="col-6">
                                                                <p className="mb-1 px-2" style={{ color: '#0E315C', fontWeight: '500' }}>{t('security_code')} *</p>
                                                                <input
                                                                    type="password"
                                                                    className="form-control"
                                                                    name="cardInfo_security_code"
                                                                    placeholder={t('***')}
                                                                    maxLength="3"
                                                                    style={{ border: requireSecurity !== '' ? '1px solid #ca4d4d' : '' }}
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onChange={(e) => cardInfo.security_code = e.target.value}
                                                                />
                                                                <span className="text-danger">{t(requireSecurity)}</span>
                                                            </div>
                                                        </div>
                                                        <span className="text-danger"></span>
                                                    </div>
                                                    <div className="mb-3 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                                                        <p className="mb-1 px-2" style={{ color: '#0E315C', fontWeight: '500' }}>{t('country_region')} *</p>
                                                        <select
                                                            defaultValue={cardInfo.country}
                                                            className="form-control"
                                                            style={{ border: requireCountry !== '' ? '1px solid #ca4d4d' : '' }}
                                                            onChange={(e) => cardInfo.country = e.target.value}>
                                                            {countryList && countryList.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.IsoCode} selected={cardInfo.country == item.IsoCode}>
                                                                        {item.CountryName}
                                                                    </option>
                                                                )
                                                            })}
                                                            {/* <option key="1" value="th" selected={cardInfo.country == 'th'}>
                                                                Thailand
                                                            </option>
                                                            <option key="2" value="sg" selected={cardInfo.country == 'sg'}>
                                                                Sigapore
                                                            </option>
                                                            <option key="3" value="jp" selected={cardInfo.country == 'jp'}>
                                                                Japan
                                                            </option> */}
                                                        </select>
                                                        <span className="text-danger">{t(requireCountry)}</span>
                                                    </div>
                                                    <div className="mb-3 col-xl-11 col-lg-11 col-md-12 col-sm-12">
                                                        <span className="text-danger" style={{ fontWeight: '700' }}>{t(requireOmise)}</span>
                                                    </div>
                                                    <div className="mb-3 col-xl-11 col-lg-11 col-md-12 col-sm-12 ">
                                                        <button type="button" className="btn btn-blue" onClick={handleSubmit}>
                                                            {t('confirm')}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )}

                                    {/* choose bank pattern */}
                                    {currentPayment.paymentType !== 'credit_card' && currentPayment.paymentType !== 'promptpay' && (
                                        <div id={currentPayment.paymentType} className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div className="py-5" style={{ paddingLeft: isTabletOrMobile ? '0px' : '40px' }}>
                                                <h2 className="font-30 mb-1 lh-1">{currentPayment.paymentName}</h2>
                                                <p className="color-grey mb-4">{currentPayment.paymentType === 'internet_banking' ? t('select_your_bank') : t('select_your_ewallet')}</p>
                                                <form>
                                                    {paymentList[currentPayment.index].Details && paymentList[currentPayment.index].Details.map((val, index) => {
                                                        return (
                                                            <>
                                                                <div id={val.PaymentType} className="mb-3 row col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex card"
                                                                    onClick={() => { setPaymentType(val.PaymentType) }}
                                                                    style={{
                                                                        flexDirection: 'row',
                                                                        margin: '0 auto',
                                                                        height: '42px',
                                                                        cursor: 'pointer',
                                                                        background: val.PaymentType === paymentType ? '#79E7FF' : ''
                                                                    }}>
                                                                    <div className="col-2 col-xl-2 col-lg-2 col-md-2 col-sm-2" style={{ margin: 'auto auto' }}>
                                                                        <img src={val.Picture} width={30}></img>
                                                                    </div>
                                                                    <div className="col-10 col-xl-10 col-lg-10 col-md-10 col-sm-10" style={{ margin: 'auto' }}>
                                                                        {val.PaymentDetailName}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                    <div className="mb-3 col-xl-12 col-lg-12 col-md-12 col-sm-12" style={{ margin: 'auto' }}>
                                                        <button type="button" className="btn btn-blue" onClick={omiseCardHandleSuccess}>
                                                            {t('confirm')}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                        )}
                    </div>

                    {currentPayment.paymentType === 'promptpay' && (
                        <>
                            {webPaymentUrl == "" && (
                                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            )}
                        </>
                    )}

                    {webPaymentUrl && (
                        <Modal show={showAlert} className="col-12 cart-placeorder">
                            <a href="#" className="close">
                                <Close className="popup-close" onClick={onCloseModal} />
                            </a>
                            <h2 className="font-30 mb-1 lh-1 text-center">{currentPayment.paymentName}</h2>
                            <p className="color-grey mb-4 text-center">{t('scan_qr_to_pay')}</p>
                            <h5 className="text-center">
                                <img src={webPaymentUrl} width={isTabletOrMobile ? 200 : 300} />
                                <div className="text-center py-3">
                                    {/* Remaining time:&nbsp; */}
                                    <Countdown
                                        date={Date.now() + qrExpired}
                                        renderer={renderer}
                                        onComplete={() => {
                                            window.location.reload();
                                        }}
                                    />
                                </div>
                                <h5 className="font-16 mt-3 lh-1" style={{
                                    fontWeight: 600,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                                </h5>
                                {showButton && (
                                    <div className="mt-4 col-8" style={{ margin: 'auto' }}>
                                        <button type="button" className="btn btn-blue" onClick={() => { setCooldown(true); checkQrStatus() }}>
                                            {t('check_payment_status')}
                                        </button>
                                    </div>
                                )}

                            </h5>
                        </Modal>
                    )}

                    <Modal show={showSelectmethod} className="col-12 cart-placeorder">
                        <a href="#" className="close">
                            <Close className="popup-close" onClick={() => setShowSelectMethod(false)} />
                        </a>
                        <h5 className="text-center text-danger">{t('warn_payment')}</h5>
                    </Modal>

                </div>
            </div>
        </div>
    );
};

export default PaymentOmise;
