import './landing2.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';


import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyImage } from "react-lazy-images";
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { ImageLoader } from 'react-loading-image'
import { ImageLoader } from "react-image-and-background-image-fade";
import Daypass from '../component/Homepage/Daypass';
import { PackageType2 } from './PackageType2';
import { apiLink, baseLink, packageTypeOther } from '../constants/DataPublic';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive'
import bannerBG from '../component/img/banner-bg.jpg';
import {
  BrowserRouter as Link
} from "react-router-dom";



import revFrameEN from '../component/img/revFrameEN.png';
import revFrameTH from '../component/img/revFrameTH.png';
import { useGlobalState } from '../global'

//Component
const LandingPage2 = (props) => {
  const { language } = props;
  const [routePath, setRoutePath] = useGlobalState('route');
  const [inactiveCam, setInactiveCam] = useGlobalState('inactiveCam');
  const [packageType, setPackageType] = useState([]);
  const [allPackage, setAllPackage] = useState([]);
  const [banner, setBanner] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [hardLink1, setHardlink1] = useState({});
  const [hardLink2, setHardlink2] = useState({});
  const [bannerUrl, setBannerUrl] = useState({});
  const [packageTicket, setPackageTicket] = useState([]);
  const [pacBanner, setPacbanner] = useState({});
  const [cart, setCart] = useState([]);

  const [itCrossCampaign, setItCrossCampaign] = useState(false);
  const [crossCampaignName, setCrossCampaignName] = useState("B2SCHOOL");


  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 767px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })

  let myCart = sessionStorage.getItem('MyCart');
  myCart = JSON.parse(myCart);

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          // dots: false
        },
      },
    ],
  };

  useEffect(() => {
    if (cart.length > 0) sessionStorage.setItem('MyCart', JSON.stringify(cart));
  }, [cart]);

  // useEffect(() => {
  //   getBanner()
  // }, [])

  useEffect(async () => {
    await getPackageTicket();
    await getAllPackage();
    // await getBanner();
  }, [language]);

  useEffect(async () => {
    let pk = packageTicket.find((x) => x.PackageCode == hardLink1.ButtonLinkUrl);
    setPacbanner(pk)
    console.log('pk', pk)
  }, [packageTicket, hardLink1]);

  async function getBanner() {
    const requestOptions = {
      method: 'GET',
      headers: { 'accept-language': language },
    };
    let path = routePath != '' ? '?prefix=' + routePath.replace('/', '') : ''
    const res = await fetch(apiLink + '/afcl/Package/Banner' + path, requestOptions);
    res
      .json()
      .then((res) => {
        let getBanner = res['Result']['PackageBanner'][0];
        if (getBanner.PackageBannerButtons.length > 0) {
          setBanner(getBanner.PackageBannerButtons);
          setBannerUrl(getBanner);
          setHardlink1(getBanner.PackageBannerButtons[0]);
          setHardlink2(getBanner.PackageBannerButtons[1]);
        }

        console.log('banner', getBanner.PackageBannerButtons);
        console.log('getBanner', getBanner);

      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Package/Banner ', error);
      });
  }

  const getPackageTicket = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'accept-language': language },
    };
    let path = routePath != '' ? '?prefix=' + routePath.replace('/', '') : ''
    const res = await fetch(apiLink + '/afcl/Package/Ticket' + path, requestOptions);
    res
      .json()
      .then((res) => {
        let packageTypeList = res['Result']['PackageSteps'];
        // setItemGroup(packageTypeList)
        setPackageTicket(res['Result']['Packages']);
        console.log('TES', res['Result']['Packages']);
        getBanner()

      })
      .catch((error) => {
        console.log(error);

        // alert('Error: API /afcl/Package/Ticket/ ' , error)
      });
  };

  async function getAllPackage() {
    const requestOptions = {
      method: 'GET',
      headers: { 'accept-language': language },
    };
    let path = routePath != '' ? '?prefix=' + routePath.replace('/', '') : ''
    const res = await fetch(apiLink + '/afcl/Package' + path, requestOptions);
    res
      .json()
      .then((res) => {
        console.log('Package,', res['Result']);
        setAllPackage(res['Result'].Packages);
        const sort = res['Result'].PackageTypes.sort((a, b) => (a.SeqNo > b.SeqNo ? 1 : -1));
        setPackageType(sort);
        props.setIsLoading(false);
        console.log('res[', res['Result'].PackageTypes);
        console.log('res[', res['Result'].Packages);
        console.log('sort', sort);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Package ', error);
      });
  }

  function LazyBackground({ src }) {
    const [source, setSource] = useState("preload.jpg")

    useEffect(() => {
      const img = new Image()
      img.src = src
      img.onload = () => setSource(src)
    }, [src])

    return (
      <div style={{ backgroundImage: `url(${source})` }}></div>
    )
  }

  const onClickBanner = (packageCode) => {
    let pk = packageTicket.find((x) => x.PackageCode == packageCode);
    if (pk != null) {
      onAddToCart(
        {
          PackageCode: pk.PackageCode,
          PackageName: pk.PackageName,
          ItemCode: '',
          ItemName: '',
          Qty: 1,
          Tax: 0,
          ItemPrice: pk.PackagePrice - pk.DiscountPrice,
          ItemDiscount: pk.DiscountPrice,
          PriceName: pk.PriceName,
          PriceUnit: '',
          TotalPrice: pk.PackagePrice - pk.DiscountPrice,
          VisitDate: null,
          VisitTime: '',
          VisitLocation: '',
          step: 1,
          status: 'normal',
        },
        false,
      );

      console.log('Is data')
    } else {
      console.log('not found');
    }
  };

  const onAddToCart = (item, isEdit) => {
    myCart = sessionStorage.getItem('MyCart');
    myCart = JSON.parse(myCart);

    console.log('BeforeAddCart, ', myCart);

    let aIndex = -1;

    if (myCart != null) {
      aIndex = myCart.findIndex((e) => {
        return e.PackageCode === item.PackageCode;
      });
    } else {
      myCart = [];
    }

    if (aIndex === -1) {
      // เพิ่ม Cart
      if (item.Qty > 0) {
        myCart = [...myCart, item];
        setCart(myCart);
      }
    } else {
      // แก้ไข Cart
      let preQty = myCart[aIndex].Qty;
      let newQty = preQty + 1;

      const editCart = myCart.map((e) => {
        if (e.PackageCode === item.PackageCode && e.ItemCode === item.ItemCode) {
          return { ...e, Qty: newQty, VisitDate: item.VisitDate, VisitLocation: item.VisitLocation, VisitTime: item.VisitTime, status: 'normal' };
        }
        return e;
      });
      setCart(editCart);
    }
  };

  const getPackageSubTypeCode = (subTypeCode) => {
    // console.log("PackagePage ", subTypeCode);
    props.getPackageSubTypeCode(subTypeCode);
  };

  const onImageLoad = () => {
    setLoaded(true)
  }

  const { t } = useTranslation();

  return (
    <>
      <div className="page homepage" style={{ pointerEvents: inactiveCam ? 'none' : 'inherit' }}>
        {/* <ImageLoader src={revFrameEN} transitionTime='0s'>
          {({ hasLoaded, shouldShowLoader, hasFailed, src }) => (
            <>
              {shouldShowLoader && !hasFailed && (
                <div className='banner-loading' style={{ display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', height: '450px' }}>
                  <h2 style={{ color: 'rgb(112 112 112)' }}> Loading ... </h2>
                </div>
              )}
              {hasFailed && (
                <div className='AwesomeoLoader__failed'>
                  Loading Fail :(
                </div>
              )}
              {hasLoaded && (
                <>
                  <div style={{ padding: '10px', fontSize: isTabletOrMobile ? '8px' : '14px', background: '#0B3259', width: '100%', height: 'auto', minHeight: '50px', color: '#ffffff', display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
                    {t('banner_header')}
                  </div>
                  <div className='banner' style={{ background: 'url(https://ohhsome.agency/buytickets/img/bg-slider.jpg) no-repeat', display: 'flex', position: 'relative' }}>
                    <>
                      <div className='banner-left'>
                        {pacBanner ?
                          <div className="button-linkf">
                            <a href={baseLink + routePath + '/ticket/' + hardLink1.ButtonLinkUrl + '/1'} onClick={() => onClickBanner(hardLink1.ButtonLinkUrl)}>
                              <button className="btn btn-darkblue">{hardLink1.ButtonDesc}</button>
                            </a>
                          </div>
                          :
                          <div className="button-linkf">
                            <a onClick={() => onClickBanner(hardLink1.ButtonLinkUrl)}>
                              <button className="btn btn-darkblue">{hardLink1.ButtonDesc}</button>
                            </a>
                          </div>
                        }
                        <div className='setwidthfirst' style={{
                          width: '405px',
                          position: 'absolute',
                          top: '15%',
                          right: '0%',
                        }}>
                          <h2 className='pre-open' style={{ fontSize: '52px', color: '#001D41' }}>{bannerUrl.BannerName}</h2>
                        </div>
                        <div className='setwidth-last' style={{
                          position: 'absolute',
                          top: '64%',
                          width: '395px',
                          right: '2%',
                        }}>
                          <p className="weight-500" style={{ top: '65%', left: '45%', fontSize: '14px', color: '#000000' }}>
                            {t('banner_apec_revads')}
                          </p>
                        </div>
                      </div>
                      <div className='banner-right'>
                        <div className='surfboy'>

                          <ImageLoader src={bannerUrl.MediaUrl} transitionTime='0s'>
                            {({ hasLoaded, shouldShowLoader, hasFailed, src }) => (
                              <>
                                {shouldShowLoader && !hasFailed && (
                                  <div style={{ display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
                                    <h2 style={{ color: 'rgb(112 112 112)' }}> Loading ... </h2>
                                  </div>
                                )}
                                {hasLoaded && (
                                  <>
                                    {pacBanner ?
                                      <>
                                        
                                        <img className='img-banner' src={revFrameEN} onLoad={onImageLoad} style={{ width: '770px' }}></img>
                                      </>
                                      :
                                      <>
                                        <img className='img-banner' src={language == 'en' ? revFrameEN : revFrameTH} onLoad={onImageLoad} style={{ width: '770px' }}></img>
                                      </>

                                    }
                                  </>
                                )}
                              </>
                            )}
                          </ImageLoader>

                        </div>
                      </div>
                    </>

                  </div>

                </>
              )}
            </>
          )}
        </ImageLoader> */}

        <ImageLoader src={bannerBG} transitionTime='0s'>
          {({ hasLoaded, shouldShowLoader, hasFailed, src }) => (
            <>
              {shouldShowLoader && !hasFailed && (
                <div className='banner-loading' style={{ display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', height: '450px' }}>
                  <h2 style={{ color: 'rgb(112 112 112)' }}> Loading ... </h2>
                </div>
              )}
              {hasFailed && (
                <div className='AwesomeoLoader__failed'>
                  Loading Fail :(
                </div>
              )}
              {hasLoaded && (
                <>

                  <div style={{ padding: '10px', fontSize: isTabletOrMobile ? '8px' : '14px', background: '#0B3259', width: '100%', height: 'auto', minHeight: '50px', color: '#ffffff', display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
                    {t('banner_header')}
                  </div>

                  <div className='banner' style={{ background: `url(${bannerBG}) no-repeat`, display: 'flex', position: 'relative' }}>

                    <>{/* href={baseLink + routePath + '/ticket/' + hardLink1.ButtonLinkUrl + '/1'} */}
                      <div className='banner-left'>
                        {pacBanner ?
                          <div className="button-linkf">
                            <a href={baseLink + routePath + '/ticket/' + hardLink1.ButtonLinkUrl + '/1'} onClick={() => onClickBanner(hardLink1.ButtonLinkUrl, false)}>
                              <button className="btn btn-darkblue">{hardLink1.ButtonDesc}</button>
                            </a>
                          </div>
                          :
                          <div className="button-linkf">
                            {/* href={hardLink1.ButtonLinkUrl} target="_blank"  */}
                            {/* <a href={baseLink + routePath + '/ticket/' + hardLink1.ButtonLinkUrl + '/1'} onClick={() => onClickBanner(hardLink1.ButtonLinkUrl)}> */}
                            <a href={baseLink + (itCrossCampaign ? '/' + crossCampaignName : routePath) + '/ticket/' + hardLink1.ButtonLinkUrl + '/1'} onClick={() => onClickBanner(hardLink1.ButtonLinkUrl, itCrossCampaign)}>
                              <button className="btn btn-darkblue">{hardLink1.ButtonDesc}</button>
                            </a>
                          </div>
                        }

                        {/* <div className='setwidthPic' style={{
                        width: '405px',
                        position: 'absolute',
                        top: '4%',
                        right: '0%',
                      }}>
                        {language == 'en' ?
                          <img src={revBadgeEN} onLoad={onImageLoad} style={{ width: '100%' }}></img>
                          :
                          <img src={revBadgeTH} onLoad={onImageLoad} style={{ width: '100%' }}></img>
                        }

                      </div> */}
                        <div className='setwidthfirst' style={{
                          width: '405px',
                          position: 'absolute',
                          top: '15%',
                          right: '0%',
                        }}>
                          <h2 className='pre-open' style={{ fontSize: '52px', color: '#001D41' }}>{bannerUrl.BannerName}</h2>
                        </div>
                        {/* <div className='setwidthsecond' style={{
                        position: 'absolute',
                        top: '40%',
                        width: '385px',
                        right: '3%',
                      }}>
                        <h2 className='aque' style={{ fontSize: '20px', color: '#004EA9' }}>
                          <span>
                            {t('banner_des2')}
                          </span>

                        </h2>
                      </div> */}
                        <div className='setwidth-last' style={{
                          position: 'absolute',
                          top: '64%',
                          width: '395px',
                          right: '2%',
                        }}>
                          <p className="weight-500" style={{ top: '65%', left: '45%', fontSize: '14px', color: '#000000' }}>
                            {bannerUrl.BannerDescription}
                            {/* {t('banner_underbutton')} */}
                          </p>
                        </div>
                      </div>
                      <div className='banner-right'>
                        <div className='surfboy'>
                          <a href={baseLink + (itCrossCampaign ? '/' + crossCampaignName : routePath) + '/ticket/' + hardLink1.ButtonLinkUrl + '/1'} onClick={() => onClickBanner(hardLink1.ButtonLinkUrl, itCrossCampaign)}>
                            <ImageLoader src={bannerUrl.MediaUrl} transitionTime='0s'>
                              {({ hasLoaded, shouldShowLoader, hasFailed, src }) => (
                                <>
                                  {shouldShowLoader && !hasFailed && (
                                    <div style={{ display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
                                      <h2 style={{ color: 'rgb(112 112 112)' }}> Loading ... </h2>
                                    </div>
                                  )}
                                  {hasLoaded && (
                                    <>
                                      {pacBanner ?
                                        <>
                                          {/* <div className='packagesave-banner'>
                                        <div class="d-flex">
                                          <div> <h1 className='price-banner'>{(pacBanner.PackagePrice - pacBanner.DiscountPrice).toLocaleString()}</h1>  <h4 className='banner-discount'>{(pacBanner.PackagePrice).toLocaleString()} {t('thb')}</h4></div>
                                          <div style={{ margin: 'auto' }}><h1 className='banner-pricename'>{t('thb')}</h1><h1 className='banner-pax'>/ {t('pax')}</h1> </div>
                                        </div>
                                        <div> <h1 className='banner-save'>{t('save_banner')} {parseInt((pacBanner.DiscountPrice * 100 / pacBanner.PackagePrice).toLocaleString())}%</h1> </div>
                                      </div> */}
                                          <img className='img-banner' src={bannerUrl.MediaUrl} onLoad={onImageLoad} style={{ width: '770px' }}></img>
                                        </>
                                        :
                                        <>
                                          <div className='packagesave-banner'>
                                            <div class="d-flex">
                                              <div> <h1 className='price-banner'></h1>  <h4 className='banner-discount'></h4></div>
                                              <div style={{ margin: 'auto' }}><h1 className='banner-pax'></h1> </div>
                                            </div>
                                            <div> <h1 className='banner-save'></h1> </div>
                                          </div>
                                          <img className='img-banner' src={bannerUrl.MediaUrl} onLoad={onImageLoad} style={{ width: '770px' }}></img>
                                        </>

                                      }


                                    </>
                                  )}
                                </>
                              )}
                            </ImageLoader>
                          </a>
                          {/* {pacBanner ?
                          <div className="button-links">
                            <a href={baseLink + '/ticket/' + hardLink1.ButtonLinkUrl + '/1'} onClick={() => onClickBanner(hardLink1.ButtonLinkUrl)}>
                              <button className="btn btn-slider">{hardLink1.ButtonDesc}</button>
                            </a>
                          </div>
                          :
                          <div className="button-links">
                            <a onClick={() => onClickBanner(hardLink1.ButtonLinkUrl)}>
                              <button className="btn btn-slider">{hardLink1.ButtonDesc}</button>
                            </a>
                          </div>
                        } */}

                        </div>
                      </div>
                    </>

                  </div>
                </>
              )}
            </>
          )}
        </ImageLoader>


        {packageType.map((e, index) => {
          if (e.PackageTypeCode !== packageTypeOther) {
            const packageList = allPackage.filter((p) => p.PackageTypeCode === e.PackageTypeCode);
            return <PackageType2 index={index} key={index} packageType={e} packageList={packageList} getPackageSubTypeCode={getPackageSubTypeCode} />;
          }
        })}

        {allPackage.length > 0 && <Daypass packageList={allPackage.filter((p) => p.PackageTypeCode === packageTypeOther)} />}

      </div>
    </>
  );
};

export default LandingPage2;
