import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import { Link, Element } from "react-scroll";
import styled from "styled-components";

import { ReactComponent as FoodIcon } from "../../component/img/fnb.svg";
import { ReactComponent as Verified } from "../../component/img/check-green.svg";
import { SubItemGroup } from './SubItemGroup';
import { baseLink, apiLink } from '../../constants/DataPublic'

const PaddedDiv = styled(Link)`
  padding-top: 10px;
  .active {
    color: blue;
  }
`;

const Step3 = (props) => {

    const { packageCode } = props
    const { currentStep } = props
    const { language } = props
    const { cartUpdate } = props
    const { itemList } = props

    //   const [itemList,setItemList] = useState([])
    const [itemSubGroup, setItemSubGroup] = useState([])

    useEffect(() => {
        getPackageCard()
    }, [])

    const getPackageCard = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "accept-language": language }
        };
        const res = await fetch(apiLink + "/afcl/Package/FB", requestOptions);
        res
            .json()
            .then((res) => {
                console.log("Step 3 : ", res)
                // setItemList(res["Result"]["Packages"])
                setItemSubGroup(res["Result"]["PackageSubTypes"])
            })
            .catch((error) => {
                console.log(error);
                // alert('Error: API /afcl/Package/FB/ ', error)
            });
    }

    const onAddToCart = (itemCart, isEdit) => {
        props.onAddToCart(itemCart, isEdit)
    }

    // ภาษา
    const { t } = useTranslation();

    return (
        <div className="col-main pe- step-3">


            <header className="sticky-top-Item">
                <div className="display-none display-block">
                    <div className="row py-3 px-3">
                        <div className="col-7 text-end">
                            <div className="d-flex align-items-center">
                                <FoodIcon className="mb-0 me-3" />
                                <h2 className="mb-0 lh-1 font-24 text-uppercase">{t('food_beverage')}</h2>
                            </div>
                        </div>
                        <div className="col-5 text-end">
                            <button className="btn btn-blue btn-skip" type="button" onClick={() => props.setCurrentStep(4)}>{t('skip')}</button>
                        </div>
                    </div>
                </div>
            </header>

            <div className="d-block d-lg-none py-3 border-bottom box-dec-step">
                <p className="text-center mb-0 weight-700" style={{ paddingTop: "5px" }}>{t('get_food_exist')}</p>
            </div>

            <div className="FlexDiv">

                <div className="LeftColumn">
                    <ul className="nav nav-pills flex-lg-column indicator ItemGroup">
                        {
                            itemSubGroup.map((e, index) => {
                                return <li><PaddedDiv className="nav-link" spy={true} smooth={true} to={e.PackageSubTypeCode} containerId="containerElement" style={{ cursor: "pointer" }}>{e.PackageSubTypeName}</PaddedDiv></li>
                            })
                        }
                    </ul>
                </div>

                <div id="containerElement" className="RightColumn">
                    {
                        itemSubGroup.map((e, index) => {
                            return (
                                <>
                                    <li className="mb-4 StyledElement mh-step3" name={e.PackageSubTypeCode} id={e.PackageSubTypeCode}>
                                        <h4 className="type mb-3"><span>{e.PackageSubTypeName}</span></h4>
                                        <SubItemGroup itemSubGroup={e.PackageSubTypeCode} itemList={itemList} currentStep={currentStep} cartUpdate={cartUpdate} onAddToCart={onAddToCart} />
                                    </li>
                                </>
                            )
                        })
                    }
                    <div className="box-space"></div>
                </div>
            </div>


        </div>
    )
}
export default Step3;