import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import BackToTopPackget from '../component/Ticket/BackToTop';
import Cart from '../component/Ticket/Cart';
import Checkout from '../component/Ticket/Checkout';
import MiniCart from '../component/Ticket/MiniCart';
import Rightbar from '../component/Ticket/Rightbar';
import StepBar from '../component/Ticket/StepBar';
import Step1 from '../component/Ticket/Step1';
import Step2 from '../component/Ticket/Step2';
import Step3 from '../component/Ticket/Step3';
import Step4 from '../component/Ticket/Step4';
import { apiLink, deviceWith } from '../constants/DataPublic';
import { useGlobalState } from '../global';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { ReactComponent as Close } from "../component/img/close.svg";
import { useTranslation } from "react-i18next";

const Ticket = (props) => {
  let user = sessionStorage.getItem('UserLogin');
  user = JSON.parse(user);


  let Promotion = sessionStorage.getItem('Promo');
  Promotion = JSON.parse(Promotion);

  const { t } = useTranslation();
  let myCart = sessionStorage.getItem('MyCart');
  myCart = JSON.parse(myCart);
  const navigate = useNavigate()
  const [routePath, setRoutePath] = useGlobalState('route');
  const [businessPolicy, setBusinessPolicy] = useGlobalState('businessPolicy');
  const { packageCode } = useParams();
  const { step } = useParams();
  const { language } = props;
  const { packageSubTypeCode } = props;
  const [currentStep, setCurrentStep] = useState(1);
  const [status, setStatus] = useState(false);
  const [itemGroup, setItemGroup] = useState([]);
  const [packageTicket, setPackageTicket] = useState([]);
  const [cart, setCart] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [cartUpdate, setCartUpdate] = useState({});
  const [moreTrillItem, setMoreTrillItem] = useState([])
  const [fbItem, setFbItem] = useState([])
  const [extra, setExtra] = useState([])
  const [paymentGateway, SetPaymentGateway] = useState(null);
  const [publicKey, setPublicKey] = useGlobalState('publicKey')

  const [isMobile, setIsMobile] = useState(false);
  const [needCheckout, setNeedCheckout] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [notEqualPackage, setNotEqualPackage] = useState(true);
  const [alertText, setAlertText] = useState("");
  const [equalArray, setEqualArray] = useState([]);
  const [notEqualArray, setNotEqualArray] = useState([]);
  const [loadDone, setLoadDone] = useState(false);
  const [editGuest, setEditGuest] = useState(false);

  //for add to cart cross campaign
  const [itCrossCampaign, setItCrossCampaign] = useState(false);
  const [crossCampaignName, setCrossCampaignName] = useState("B2SCHOOL");
  const [crossPackageTicket, setCrossPackageTicket] = useState([]);

  useEffect(() => {
    console.log('Ticket, ', packageSubTypeCode);
    console.log('Ticket Package, ', packageCode, ' Step: ', step);
    // props.setRoutePath(window.location.pathname.split('/')[1])
    // Mobile Device 575.98 , Tablet 768

    if (document.body.offsetWidth < deviceWith) {
      setIsMobile(true);
    }

    if (myCart) {
      if (myCart.filter((e) => e.step == 1).length == 0) {
        // sessionStorage.setItem('MyCart', JSON.stringify([]));
        // setCart([])
        setCurrentStep(1)
        navigate("/ticket/" + packageCode + "/" + 1)
        setStatus(true)
      } else {
        setCurrentStep(step)
        navigate("/ticket/" + packageCode + "/" + step)
        setStatus(false)
      }
    }

    if (myCart !== null) {
      console.log('Set Cart', myCart);
      setCart(myCart);
    }

    if (user !== null) {
      setUserInfo(user);
    }

    Promotion = Promotion === null ? { discountPromo: '', promoCode: '', havePro: false, packageType: '' } : Promotion;
    sessionStorage.setItem('Promo', JSON.stringify(Promotion));

    console.log("currentStep currentStep", currentStep)
    console.log("indexxxcartcart", cart)
    console.log("notEqualPackage", notEqualPackage)
    // getPackageCard();
  }, []);

  useEffect(async () => {
    if (language) {
      if (itCrossCampaign) {
        getPackageTicketCrossCampaign()
      }
      if (routePath == "") {
        getExtra()
        getMoreTrill()
        await getFB()
      }
      await getPackageCard();
      setTimeout(() => {
        setLoadDone(true)
      }, 100);
    }

  }, [language]);


  useEffect(() => {
    myCart = sessionStorage.getItem('MyCart');
    myCart = JSON.parse(myCart);
    console.log("myCart", myCart)
    if (myCart) {
      if (myCart.length == 0) {
        setNotEqualPackage(false)
      }

      let allTicket = []
      // if (itCrossCampaign) {
      //   if (myCart.length > 0 && crossPackageTicket.length > 0) {
      //     crossPackageTicket.forEach((x) => { allTicket.push(x) })
      //   }
      // }
      if (routePath == "") {
        if (myCart.length > 0 && packageTicket.length > 0) {
          packageTicket.forEach((item) => { allTicket.push(item) })
          if (!!moreTrillItem && moreTrillItem.length > 0) {
            moreTrillItem.forEach((x) => { allTicket.push(x) })
          }
          if (!!fbItem && fbItem.length > 0) {
            fbItem.forEach((x) => { allTicket.push(x) })
          }
          if (!!extra && extra.length > 0) {
            extra.forEach((x) => { allTicket.push(x) })
          }
        }
      } else {
        if (myCart.length > 0 && packageTicket.length > 0) {
          packageTicket.forEach((item) => { allTicket.push(item) })
        }
      }

      let notEqualArray = []
      let EqualArray = []
      console.log("allTicket", allTicket)
      if (allTicket.length > 0) {
        notEqualArray = myCart.filter((cart) => !allTicket.some((api) => cart.PackageCode === api.PackageCode));
        EqualArray = myCart.filter((cart) => allTicket.some((api) => cart.PackageCode === api.PackageCode));
      }

      console.log("notEqualArray Before", notEqualArray)
      console.log("EqualArray Before", EqualArray)
      console.log("loadDone", loadDone)
      let conditionPopup
      conditionPopup = routePath == "" ? notEqualArray.length > 0 && loadDone : notEqualArray.length > 0
      console.log("conditionPopup", conditionPopup)
      if (conditionPopup) {
        setNotEqualArray(notEqualArray)
        setEqualArray(EqualArray)
        deletePackage()
        // setShowAlert(true)
        setAlertText("delete_not_equal")
        return
      } else {
        setNotEqualPackage(false)
        setShowAlert(false)
      }
    } else {
      setNotEqualPackage(false)
      setShowAlert(false)
    }
  }, [packageTicket, moreTrillItem, fbItem, extra, loadDone, crossPackageTicket])

  // const deletePackage = () => {
  //   sessionStorage.setItem('MyCart', JSON.stringify(equalArray));
  //   setCart(equalArray)
  //   setShowAlert(false)
  //   // window.location.reload();
  //   console.log("equalArray", equalArray)
  //   console.log("equalArray", myCart)
  // }

  const deletePackage = async () => {
    if (!!user && user.confirmationNo != "") {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'accept-language': language },
        body: JSON.stringify({
          UserCode: user.userCode,
          ConfirmationNo: user.confirmationNo
        }),
      };
      // /afcl/Package/Cart/RemovePackage
      const res = await fetch(apiLink + '/afcl/Package/Cart/RemovePackage', requestOptions);
      res
        .json()
        .then((res) => {
          const result = res['Success']
          if (result) {
            user.confirmationNo = ""
            sessionStorage.setItem('UserLogin', JSON.stringify(user));
            sessionStorage.setItem('MyCart', JSON.stringify(equalArray));
            setCart(equalArray)
            setShowAlert(false)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      sessionStorage.setItem('MyCart', JSON.stringify(equalArray));
      setCart(equalArray)
      setShowAlert(false)
    }
  }

  useEffect(() => {
    console.log('packageTicket', packageTicket);
    let pk = packageTicket.filter((a) => {
      return myCart.find((b) => { return a.PackageCode === b.PackageCode });
    });
    pk.forEach((a) => {
      myCart.forEach((b) => {
        if (a.PackageCode === b.PackageCode) { b.PackageName = a.PackageName; }
      });
    });
    sessionStorage.setItem('MyCart', JSON.stringify(myCart));
    setCart(myCart);
  }, [packageTicket]);

  useEffect(() => {
    console.log('crossPackageTicket', crossPackageTicket);
    let pk = crossPackageTicket.filter((a) => {
      return myCart.find((b) => { return a.PackageCode === b.PackageCode });
    });
    pk.forEach((a) => {
      myCart.forEach((b) => {
        if (a.PackageCode === b.PackageCode) { b.PackageName = a.PackageName; }
      });
    });
    sessionStorage.setItem('MyCart', JSON.stringify(myCart));
    setCart(myCart);
  }, [crossPackageTicket]);

  useEffect(() => {
    console.log('moreTrillItem', moreTrillItem);
    if (!!moreTrillItem) {
      let pk = moreTrillItem.filter((a) => {
        return myCart.find((b) => {
          return a.PackageCode === b.PackageCode;
        });
      });
      pk.forEach((a) => {
        myCart.forEach((b) => {
          if (a.PackageCode === b.PackageCode) {
            b.PackageName = a.PackageName;
          }
        });
      });
      sessionStorage.setItem('MyCart', JSON.stringify(myCart));
      setCart(myCart);
    }

  }, [moreTrillItem]);


  useEffect(() => {
    console.log('fbItem', fbItem);
    if (!!fbItem) {
      let pk = fbItem.filter((a) => {
        return myCart.find((b) => {
          return a.PackageCode === b.PackageCode;
        });
      });
      pk.forEach((a) => {
        myCart.forEach((b) => {
          if (a.PackageCode === b.PackageCode) {
            b.PackageName = a.PackageName;
          }
        });
      });
      sessionStorage.setItem('MyCart', JSON.stringify(myCart));
      setCart(myCart);
    }

  }, [fbItem]);

  useEffect(() => {
    console.log('extra', extra);
    if (!!extra) {
      let pk = extra.filter((a) => { return myCart.find((b) => { return a.PackageCode === b.PackageCode; }); });
      pk.forEach((a) => {
        myCart.forEach((b) => {
          if (a.PackageCode === b.PackageCode) { b.PackageName = a.PackageName; }
        });
      });
      sessionStorage.setItem('MyCart', JSON.stringify(myCart));
      setCart(myCart);
    }

  }, [extra]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    console.log("DDDDDDDDDDDDDDDDDDDDD")
  }, [currentStep])

  useEffect(() => {
    setCurrentStep(parseInt(step));
  }, [step]);

  const getPackageCard = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'accept-language': language },
    };
    let path = routePath != '' ? '?prefix=' + routePath.replace('/', '') : '';
    const res = await fetch(apiLink + '/afcl/Package/Ticket' + path, requestOptions);
    res
      .json()
      .then(async (res) => {
        let packageTypeList = res['Result']['PackageSteps'];
        packageTypeList = packageTypeList.sort((a, b) => (a.StepId > b.StepId ? 1 : -1));
        setItemGroup(packageTypeList);
        setPackageTicket(res['Result']['Packages']);
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Package/Ticket/ ' , error)
      });
  };

  const getPackageTicketCrossCampaign = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'accept-language': language },
    };
    let path = '?prefix=' + crossCampaignName
    const res = await fetch(apiLink + '/afcl/Package/Ticket' + path, requestOptions);
    res
      .json()
      .then((res) => {
        setCrossPackageTicket(res['Result']['Packages']);
      })
      .catch((error) => {
        console.log(error);

      });
  };


  const getMoreTrill = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language }
    };
    const res = await fetch(apiLink + "/afcl/Package/MoreThill", requestOptions);
    res
      .json()
      .then((res) => {
        const packageAll = res["Result"]["Packages"]
        setMoreTrillItem(packageAll)
      })
      .catch((error) => {
        console.log(error);

      });
  }

  const getFB = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language }
    };
    const res = await fetch(apiLink + "/afcl/Package/FB", requestOptions);
    res
      .json()
      .then((res) => {
        console.log("Step 3 : ", res)
        setFbItem(res["Result"]["Packages"])
      })
      .catch((error) => {
        console.log(error);

      });
  }

  const getExtra = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language }
    };
    const res = await fetch(apiLink + "/afcl/Package/Extra", requestOptions);
    res
      .json()
      .then((res) => {
        console.log("Step 4: ", res)
        let iList = res["Result"]["Packages"]
        setExtra(iList)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const onAddToCart = (item, isEdit) => {
    if (!isEdit) {
      // เพิ่ม Cart
      // if (item.Qty > 0) {
      //   if(item.VisitDate != null){
      //    myCart.forEach((b) => {
      //       if(b.VisitDate != null){
      //         b.VisitDate = item.VisitDate;
      //       }
      //     });
      //     sessionStorage.setItem('MyCart', JSON.stringify(myCart));
      //     setCart(myCart);
      //     setCart((cart) => {
      //       return [...cart, item];
      //     });
      //   } else {
      //     setCart((cart) => {
      //       return [...cart, item];
      //     });
      //   }
      // }
      if (item.Qty > 0) {
        setCart((cart) => {
          return [...cart, item];
        });
      }
    } else {
      // แก้ไข Cart
      if (item.Qty > 0) {
        const editCart = cart.map((e) => {
          if (e.PackageCode === item.PackageCode && e.ItemCode === item.ItemCode) {
            return { ...e, Qty: item.Qty, VisitDate: item.VisitDate, VisitLocation: item.VisitLocation, VisitTime: item.VisitTime, status: 'normal', PackageName: item.PackageName, TotalPrice: item.TotalPrice, TotalPriceExVat: item.TotalPriceExVat };
          }
          return e;
        });
        setCart(editCart);
      } else {
        onRemoveCart(item.PackageCode, item.ItemCode);
      }
    }
  };

  const onEditCart = (pCode, iCode) => {
    const editCart = cart.map((e) => {
      if (e.PackageCode === pCode && e.ItemCode === iCode) {
        return { ...e, status: 'edit' };
      }
      return e;
    });

    setCart(editCart);
  };

  const onRemoveCart = (pCode, iCode) => {
    const index = cart.findIndex((e) => {
      return e.PackageCode === pCode && e.ItemCode === iCode;
    });

    setCart([...cart.slice(0, index), ...cart.slice(index + 1, cart.length)]);

    if (cart.length == 1) {
      sessionStorage.setItem('Promo', JSON.stringify({ discountPromo: '', promoCode: '', havePro: false, promoType: '' }));
    }

    console.log("indexxx", cart)
  };

  useEffect(() => {
    if (cart != null) {
      props.getCartItem(cart.length);
      sessionStorage.setItem('MyCart', JSON.stringify(cart));
      console.log('packageTicket', packageTicket);
      if (status && myCart.length == 0) {
        sessionStorage.setItem('MyCart', JSON.stringify([]));
        setStatus(false)
      }
    }

  }, [cart]);

  useEffect(() => {
    if (currentStep === 5) {
      getPaymentGateway()
    }
    console.log("publicKey ticket", publicKey)
  }, [currentStep])


  const getPaymentGateway = async () => {
    // /afcl/Package/GetActivePayment
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language, 'Content-Type': 'application/json', }
    };
    await fetch(apiLink + '/afcl/Package/GetActivePayment', requestOptions).
      then(function (response) {
        return response.json();
      })
      .then(function (data) {
        if (data["Success"]) {
          const items = data["Result"];
          SetPaymentGateway(items[0]['PaymentGatwayCode'])
        } else {
          console.log("bad request", data["Result"])
        }
      })
  }





  return (
    <div className="page page-steps">
      {/* {routePath == "" && (
        <>
          {itemGroup.length > 0 && (
            <header className="sticky-top">
              <StepBar itemGroup={itemGroup} packageCode={packageCode} currentStep={currentStep} setCurrentStep={setCurrentStep} />
            </header>
          )}
        </>
      )} */}

      <div className="main ">
        <div className="container">
          <div className="row">
            {currentStep === 1 && (
              <Step1 step={step} packageCode={packageCode} packageTicket={packageTicket} currentStep={currentStep} cartUpdate={cartUpdate} onAddToCart={onAddToCart} onRemoveCart={onRemoveCart} />
            )}
            {currentStep === 2 && (
              <Step2
                language={language}
                itemList={moreTrillItem}
                packageCode={packageCode}
                currentStep={currentStep}
                cartUpdate={cartUpdate}
                onAddToCart={onAddToCart}
                onRemoveCart={onRemoveCart}
                setCurrentStep={setCurrentStep}
                setCart={setCart}
              />
            )}
            {currentStep === 3 && (
              <Step3
                language={language}
                itemList={fbItem}
                packageCode={packageCode}
                currentStep={currentStep}
                cartUpdate={cartUpdate}
                onAddToCart={onAddToCart}
                onRemoveCart={onRemoveCart}
                setCurrentStep={setCurrentStep}
                setCart={setCart}
              />
            )}
            {currentStep === 4 && (
              <Step4
                language={language}
                itemList={extra}
                packageCode={packageCode}
                currentStep={currentStep}
                cartUpdate={cartUpdate}
                packageSubTypeCode={packageSubTypeCode}
                onAddToCart={onAddToCart}
                onRemoveCart={onRemoveCart}
                setCurrentStep={setCurrentStep}
              />
            )}
            {currentStep === 5 && (needCheckout || !isMobile) && <Checkout language={language} cart={cart} userInfo={userInfo} setEditGuest={(e) => setEditGuest(e)} />}

            <Rightbar
              editGuest={editGuest}
              notEqualPackage={notEqualPackage}
              publicKey={publicKey}
              language={language}
              packageCode={packageCode}
              currentStep={currentStep}
              cart={cart}
              paymentGateway={paymentGateway}
              setCartUpdate={setCartUpdate}
              onAddToCart={onAddToCart}
              onEditCart={onEditCart}
              onRemoveCart={onRemoveCart}
              setCurrentStep={setCurrentStep}
            />

            {currentStep === 5 && (
              <Cart
                editGuest={editGuest}
                notEqualPackage={notEqualPackage}
                publicKey={publicKey}
                language={language}
                packageCode={packageCode}
                needCheckout={needCheckout}
                setNeedCheckout={setNeedCheckout}
                currentStep={currentStep}
                paymentGateway={paymentGateway}
                setCartUpdate={setCartUpdate}
                cart={cart}
                onAddToCart={onAddToCart}
                onEditCart={onEditCart}
                onRemoveCart={onRemoveCart}
              />
            )}
            {currentStep !== 5 && <MiniCart packageCode={packageCode} currentStep={currentStep} cart={cart} />}
          </div>
        </div>
      </div>

      {/* Delete Confirm Popup ------------------------------------------------------- */}
      {/* <Modal show={showAlert} className="col-12 cart-placeorder">
        <h5 className="text-center text-danger mb-5">{t(alertText)}</h5>
        {notEqualArray && notEqualArray.map((item, index) => ((
          <h5 className="text-center" key={index}>{item.PackageName} : {t('price')} {item.TotalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}</h5>
        )))}
        <div className="w-100 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
          <button className="btn" style={{ height: '40px', background: '#d63737', borderRadius: '5px', fontWeight: '700', border: '0px', color: '#fff' }} onClick={deletePackage}>{t('delete')}</button>
        </div>
      </Modal> */}

      <BackToTopPackget />

      {!loadDone && (
        <div className="spinner">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default Ticket;
