
const AddTagFunction = () => {

    const addGoogleTagAW = () => {
        var script = document.createElement('script');
        script.id = 'GoogleTagAW';
        script.src = "https://www.googletagmanager.com/gtag/js?id=AW-11411097465";
        script.async = true;
        script.innerHTML = `
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-11411097465');
          `;
        document.head.appendChild(script);
    }

    const addConversionScript = () => {
        var scriptElement = document.getElementById('GoogleTagAW')
        if (scriptElement) {
            scriptElement.innerHTML = `
            window.dataLayer = window.dataLayer || []; 
            function gtag(){dataLayer.push(arguments);} 
            gtag('js', new Date()); 
            gtag('config', 'AW-11411097465');
            gtag('event', 'conversion', {'send_to': 'AW-11411097465/LEcDCNC2oPwYEPmOnsEq'});
          `;
            document.head.appendChild(scriptElement);
        }
    }

    const dynamicAddScript = async (scriptTags) => {

        const scriptTagsArray = scriptTags.split('</script>').filter(Boolean);
        const scriptArray = scriptTagsArray.map((scriptTag) => {
            const srcMatch = scriptTag.match(/<script\s+async\s+src=['"]([^'"]+)['"]/);
            if (srcMatch) {
                return { src: srcMatch[1] };
            }

            const textMatch = scriptTag.match(/<script>([\s\S]+?)$/);
            if (textMatch) {
                const textWithoutNewlines = textMatch[1].replace(/\n/g, '');
                return { text: textWithoutNewlines };
            }

            return null;
        }).filter(Boolean);

        scriptArray.forEach((script, i) => {
            if (script.src) {
                var scriptSrc = document.createElement('script');
                scriptSrc.id = 'adsTagHeader' + (i + 1);
                scriptSrc.src = script.src;
                scriptSrc.async = true;
                document.head.appendChild(scriptSrc);
            }
            if (script.text) {
                var scriptText = document.createElement('script');
                scriptText.id = 'adsTagHeader' + (i + 1);
                scriptText.async = true;
                scriptText.innerHTML = script.text;
                document.head.appendChild(scriptText);
            }
        })
    }

    return { addGoogleTagAW, addConversionScript, dynamicAddScript }
}

export default AddTagFunction