
const CalVatExclude = (pk, policy) => {
    const isVat = () => {
        console.log("policy", policy);
        let isItemPrice
        let isDiscount
        if (pk.ItemPrice) {
            isItemPrice = pk.ItemPrice
            isDiscount = pk.ItemDiscount
        } else {
            isItemPrice = pk.PackagePrice
            isDiscount = pk.DiscountPrice
        }
        const vatPrice = isItemPrice * (policy.VatRate / (100 + policy.VatRate))
        const vatDiscount = isDiscount * (policy.VatRate / (100 + policy.VatRate))
        pk.ItemPriceExVat = (isItemPrice - vatPrice)
        pk.ItemDiscountExVat = (isDiscount - vatDiscount)
        pk.isExCludeVat = !policy.IsIncludeVat
        console.log("PKVat", pk);
        return pk
    }
    return isVat()
}

export default CalVatExclude