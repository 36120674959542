import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { baseLink } from "../constants/DataPublic";
import { useLocation, useNavigate } from 'react-router-dom';
import { Markup } from 'interweave';
import { useMediaQuery } from 'react-responsive'
import { useGlobalState } from '../global'

const Done = (props) => {
	const { language } = props
	const { state } = useLocation();

	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 990px)' })

	const [keepState, setKeepState] = useState();
	const [routePath, setRoutePath] = useGlobalState('route');

	let user = sessionStorage.getItem('UserLogin')
	user = JSON.parse(user)

	let itCrossCam = sessionStorage.getItem('itCrossCampaign');
	itCrossCam = JSON.parse(itCrossCam);

	const navigate = useNavigate();

	useEffect(() => {
		console.log("Done: ", user);
		console.log("confirmNo: ", state);
		// window.location.reload()
		setKeepState(state)
		props.getCartItem(0)
		user.confirmationNo = "";
		sessionStorage.setItem('UserLogin', JSON.stringify(user));
	}, [])

	// useEffect(() => {
	// 	var scriptElement = document.getElementById('headerTagAds')
	// 	if (scriptElement) {
	// 		scriptElement.text.includes('_lt')
	// 		console.log("scriptElement.text.includes('_lt')", scriptElement.text.includes('_lt'));
	// 		if (scriptElement.text.includes('_lt')) {
	// 			addToHeader()
	// 		}
	// 	}
	// 	console.log("scriptElement", scriptElement);
	// }, [document.getElementById('headerTagAds')])

	// const addToHeader = () => {
	// 	//Line Tag
	// 	var lineScript = document.createElement('script');
	// 	lineScript.id = 'LinePaymentSuccess';
	// 	lineScript.text = `_lt('send', 'cv', {
	// 		type: 'PaymentSuccess'
	// 	  },['90147720-6dcd-474f-b604-09bf64403d86']);` //UAT
	// 	document.head.appendChild(lineScript);
	// }

	const goHome = () => {
		user.confirmationNo = "";
		sessionStorage.setItem('UserLogin', JSON.stringify(user));
		// navigate("/")
		window.location.reload()
		setKeepState(null)
	}

	// ภาษา
	const { t } = useTranslation();

	return (
		<div className="page page-steps">
			<div class="col-lg-12 py-5">
				<div class="d-flex justify-content-center height-full">
					<div class="pt-5 done" style={{ width: '80%' }}>
						<h2 class="font-40 color-main mb-2 text-center">{t('you_all_set')}</h2>
						<p class="font-18 text-center" style={{ fontWeight: '600' }}>{t('thank_you')}</p>
						<p class="mb-5 text-center" style={{ width: isTabletOrMobile ? '100%' : '50%', margin: 'auto' }}>{t('junk_box')}</p>
						<h5 class="font-20 mb-3 text-left" style={{ fontWeight: '500' }}>{t('your_booking_reference')} : {keepState && (
							keepState.confirmationNo
						)}
						</h5>
						<div>
							{keepState && (
								<Markup content={" " + keepState.TagHtml} />
							)}

						</div>
						<div style={{ width: '100%', margin: 'auto' }}>
							<a href={itCrossCam ? baseLink + '/' : (baseLink + routePath == "" ? '/' : baseLink + routePath)}>
								<button class="btn btn-main btn-done" onClick={goHome}>{t('back')}</button>
							</a>
						</div>
						{/* <h5 class="font-20 mb-3" style={{ fontWeight: '500' }}>{t('please_checkyour_email')}</h5> */}
						<h2 class="font-20 mb-5"></h2>
					</div>
				</div>
				{/* <Markup content={" " + state.TagHtml} /> */}
			</div>
		</div>
	)
}
export default Done
