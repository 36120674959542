import React, { useEffect, useState } from 'react'
import showTerm from './showTerm';
import { useTranslation } from 'react-i18next';

const AcceptTerm = (props) => {
    const { accept, setChangeAccept } = props
    const { t } = useTranslation();

    const onChangeAccept = (e) => {
        const acceptUpdate = { ...accept, [e.target.name]: e.target.checked }
        setChangeAccept(acceptUpdate)
    }

    return (
        <div className='d-flex flex-column gap-2'>
            <div className="d-flex">
                <input type="checkbox" className="form-check-input cursor-pointer" id="IsAcceptPolicy" name="IsAcceptPolicy" checked={accept.IsAcceptPolicy} onChange={onChangeAccept} />
                <label htmlFor="IsAcceptPolicy" class="form-check-label cursor-pointer" style={{ padding: '.2rem 0 0 1rem', fontSize: '13px' }}>
                    <div dangerouslySetInnerHTML={{ __html: t('accept1') }}></div>
                </label>
            </div>
            <div className="d-flex">
                <input type="checkbox" className="form-check-input cursor-pointer" id="IsAcceptMarketing" name="IsAcceptMarketing" checked={accept.IsAcceptMarketing} onChange={onChangeAccept} />
                <label htmlFor="IsAcceptMarketing" class="form-check-label cursor-pointer" style={{ padding: '.2rem 0 0 1rem', fontSize: '13px' }}>
                    <div dangerouslySetInnerHTML={{ __html: t('accept2') }}></div>
                </label>
            </div>
        </div>
    )
}

export default AcceptTerm