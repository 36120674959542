import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Markup } from 'interweave';
import { gapi, loadAuth2 } from 'gapi-script';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import 'react-responsive-modal/styles.css';

//Components
import GGLogin from '../component/GGLogin';
import FBLogin from '../component/FBLogin';
import AcceptTerm from '../customHook/AcceptTerm';

//Image
import logo from '../component/img/logo-xl.png';
import { ReactComponent as Close } from '../component/img/close.svg';
import { ReactComponent as Vector } from '../component/img/vector.svg';

//css
import '../component/css/style.css';
import '../component/css/custom.css';

import useLogin from '../customHook/useLogin';
import showTerm from '../customHook/showTerm';
import { clientId } from '../constants/DataPublic';
import { useGlobalState } from '../global'


const Signin = (props) => {
  const navigate = useNavigate();
  const { language } = props;

  const [routePath, setRoutePath] = useGlobalState('route');
  const [accept, setAccept] = useState({
    IsAcceptPolicy: false,
    IsAcceptMarketing: false,
  });
  const [policyInvalid, setPolicyInvalid] = useState(false);

  let user = sessionStorage.getItem('UserLogin');
  user = JSON.parse(user);

  useEffect(() => {
    if (user != null && user.isLogin) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    let checkRoute = ['/signin', '/pass', '/forget', '/changepw', '/signup', '/setpw', '/ticket', '/guestCheckout', '/done', '/visitorInfo', '/payment']
    if (checkRoute.includes(routePath)) {
      console.log("Default signin")
    } else {
      console.log("Go!! signin")
    }
  }, [])

  // Login with Email
  let { message, setEmail, submitLogin } = useLogin({ lang: language });

  // Show Terms & Conditions
  let { open, subject, terms, agree, setAgree, onOpenModal, onCloseModal, getTerms, onConfirm } = showTerm();

  const [ggUser, setGGUser] = useState({});
  const [fbUser, setFBUser] = useState({});
  const [needRegist, setNeedRegist] = useState(false);

  const getGGUser = (ggUser) => {
    setGGUser(ggUser);
    props.getGGUser(ggUser);
  };

  const getFBUser = (fbUser) => {
    setFBUser(fbUser);
    props.getFBUser(fbUser);
  };

  const getNeedRegist = (needRegist) => {
    setNeedRegist(needRegist);
  };

  useEffect(() => {
    if (needRegist) {
      getTerms(language);
      setNeedRegist(false);
    }
  }, [needRegist]);

  // Login with Google
  useEffect(async () => {
    await loadAuth2(gapi, clientId, "");
  }, []);

  const setUserCode = (userCode) => {
    props.setUserCode(userCode);
  };

  // ภาษา
  const { t } = useTranslation();

  return (
    <div>
      <div className="page page-steps">
        <div className="section py-5">
          <div className="container">
            <div className="row">
              <div className="logosignin">
                <img src={logo} id="logosignin" />
              </div>
              <div className="col-xl-5 col-lg-6 col-md-7 col-sm-12 box-body box-border box-signin signinradius" style={{ margin: '0 auto' }}>
                <div className="mb-3">
                  <h2 className="main-title mb-3">{t('sign_in_to_your_account')}</h2>
                  <label htmlFor="exampleFormControlInput1" className="form-label text-color">
                    {t('email')}*
                  </label>
                  <input
                    type="email"
                    pattern="/^([w-]+.?){0,2}[w-]+@([w-]+.?){0,2}[w-]+$/"
                    className="form-control"
                    id="exampleFormControlInput1"
                    placeholder="name@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <span className="text-danger">{t(message)}</span>
                </div>
                <div className="mb-3">
                  <Button type="button" className="btn btn-link btn-lb text-capitalize" onClick={submitLogin}>
                    {t('login')}
                  </Button>
                </div>

                <div className="mb-3">
                  <p style={{ textAlign: 'center' }} className="mb-3">
                    {t('or')}
                  </p>
                </div>

                <div className="mb-3">
                  <GGLogin language={language} ggtext={'continue_with_google'} getGGUser={getGGUser} getNeedRegist={getNeedRegist} setUserCode={setUserCode} />
                </div>
                {/* <div className="mb-5">
                  <FBLogin language={language} fbtext={'continue_with_facebook'} getFBUser={getFBUser} getNeedRegist={getNeedRegist} setUserCode={setUserCode} />
                </div> */}

                <div className="mb-3">
                  <h4>{t('not_registered_join_now')}</h4>
                  <p className="color-grey weight-500">{t('join_now_desc')}</p>
                </div>

                <div className="box-main-body ">
                  <div className="row weight-500 mb-3">
                    <div className="col-1">
                      <Vector />
                    </div>
                    <div className="col-11 spanpadding">{t('join_list1')}</div>
                  </div>
                  <div className="row weight-500 mb-3">
                    <div className="col-1">
                      <Vector />
                    </div>
                    <div className="col-11 spanpadding">{t('join_list2')}</div>
                  </div>
                  <div className="row weight-500">
                    <div className="col-1">
                      <Vector />
                    </div>
                    <div className="col-11 spanpadding">{t('join_list3')}</div>
                  </div>
                </div>

                <div className="mb-3">
                  <Button type="button" className="btn btn-link btn-db" onClick={() => getTerms(language)}>
                    {t('join_now')}
                  </Button>
                  <Modal show={open} className="col-12 signinpage popupterm">
                    <div style={{ textAlign: 'right' }}>
                      <Close className="popup-close" style={{ cursor: 'pointer' }} onClick={onCloseModal} />
                    </div>
                    <div className="logosignin">
                      <img src={logo} id="logosignin" />
                    </div>

                    <h5 className="text-center mb-5">{subject}</h5>
                    <Markup content={" " + terms} />
                    {/* <div className="text-center mb-5">
                      <input type="checkbox" className="form-check-input" id="vehicle1" name="vehicle1" onChange={(e) => setAgree(e.target.checked)} />
                      <label hntmlFor="vehicle1" class="form-check-label" style={{ padding: '.2rem 0 0 1rem' }}>
                        <u>{t('i_agree')}</u>
                      </label>
                    </div> */}
                    <div className='d-flex justify-content-center mb-4'>
                      <div className="w-75">
                        <AcceptTerm accept={accept} setChangeAccept={setAccept} />
                      </div>
                    </div>
                    {policyInvalid && <span className="text-danger text-center" style={{ fontSize: '12px' }}>{t('must_accept_policy')}</span>}
                    <div className="col-5" style={{ margin: '0 auto', paddingTop: '1em' }}>
                      <Button type="button" className="btn btn-link btn-db text-capitalize" onClick={(e) => {
                        setPolicyInvalid(!accept.IsAcceptPolicy)
                        if (!accept.IsAcceptPolicy) return
                        onConfirm(accept)
                      }} >
                        {t('confirm')}
                      </Button>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
