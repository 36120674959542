import React, { useEffect, useRef, useState } from 'react';
import pending from '../component/img/paymentpending.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { apiLink } from "../constants/DataPublic";
import { useMediaQuery } from 'react-responsive'

//Component
const PaymentProcessing = (props) => {
    const { language } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 990px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 990px)' })

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    useEffect(() => {
        if (user.InvoiceNumber != "") {
            const interval = setInterval(async () => {
                await checkStatus()
            }, 6000)
            return () => clearInterval(interval)
        }
    }, [user.InvoiceNumber])

    const checkStatus = async () => {
        const requestOptions = {
            method: "GET",
            headers: { "accept-language": language, 'Content-Type': 'application/json', },
        };
        // /afcl/Package/Omise/Inquiry
        await fetch(apiLink + '/afcl/Package/Omise/Inquiry' + '?ConfirmationNo=' + user.confirmationNo + '&UserCode=' + user.userCode, requestOptions).
            then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data["Success"]) {
                    const items = data["Result"];
                    if (items['ResponseCode'] === "successful") {
                        sessionStorage.removeItem("MyCart")
                        navigate("/done", { state: { confirmationNo: user.confirmationNo, TagHtml: items["TagHtml"] } })
                        addToHeader()
                    } else if (items['ResponseCode'] === "failed") {
                        navigate("/paymentfail", { state: { ResponseDetail: items["ResponseDetail"], } })
                    } else if (items['ResponseCode'] === "expired") {
                    } else if (items['ResponseCode'] === "pending") {
                    } else if (items['ResponseCode'] === "reversed") {
                    } else {
                        console.log("Error")
                    }
                } else {
                    console.log("bad request", data["Result"])
                }
            })
    }

    const addToHeader = () => {
        var scriptElement = document.getElementById('headerTagAds')
        if (scriptElement) {
            scriptElement.text.includes('_lt')
            console.log("scriptElement.text.includes('_lt')", scriptElement.text.includes('_lt'));
            if (scriptElement.text.includes('_lt')) {
                var lineScript = document.createElement('script');
                lineScript.id = 'LinePaymentSuccess';
                lineScript.text = `_lt('send', 'cv', {
			type: 'PaymentSuccess'
		  },['90147720-6dcd-474f-b604-09bf64403d86']);` 
                document.head.appendChild(lineScript);
            }
        }
    }

    const goHome = () => {
        navigate("/")
    }

    return (
        <div className="page homepage pb-5" style={{ minHeight: isTabletOrMobile ? 'auto' : 'calc(100vh - 183px)' }}>
            <div class="card mt-5" style={{
                width: isTabletOrMobile ? '80%' : '50%',
                margin: 'auto',
            }}>
                <div class="card-body" >
                    <h2 className="lh-1" style={{
                        width: '100%',
                        height: '100%',
                        fontWeight: '700',
                        fontSize: '18px',
                        color: '#0e315c'
                    }}>
                        {t('smart_finder')}
                    </h2>
                    <h2 className="lh-1" style={{
                        width: '100%',
                        height: '100%',
                        fontSize: '16px',
                        fontWeight: '500',
                    }}>
                        {user.InvoiceNumber}
                    </h2>
                    <h2 className="lh-1" style={{
                        width: '100%',
                        height: '100%',
                        fontWeight: '700',
                        fontSize: '18px',
                        color: '#0e315c'
                    }}>
                        {user.totalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                    </h2>
                </div>
            </div>
            <div style={{ height: '60%', display: 'flex', justifyContent: 'center', marginTop: isTabletOrMobile ? '5rem' : '10rem', alignItems: 'center' }}>
                {/* <img src={pending} style={{ height: '80%' }}></img> */}
                <CircularProgress size={150} color="inherit" />
            </div>
            <div style={{ height: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '40px' }}>
                <h5 style={{ fontWeight: '800', fontSize: '18px', width: '70%', textAlign: 'center' }}>
                    {t('please_wait')}
                </h5>
            </div>
        </div>
    );
};

export default PaymentProcessing;




