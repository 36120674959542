import React from "react";

import logo from "./img/logo.png";
import LogoKohTalu from "./img/LogoKohTalu.png";
import logoinvert from "./img/logo-invert.png";

import { useTranslation } from "react-i18next";
import { useGlobalState } from '../global'

import { ReactComponent as Line } from "./img/line.svg";
import { ReactComponent as Youtube } from "./img/youtube.svg";
import { ReactComponent as Facebook } from "./img/facebook.svg";
import { ReactComponent as Instagram } from "./img/instagram.svg";

import "./css/style.css";

export const Footer = () => {
  const [routePath, setRoutePath] = useGlobalState('route');
  const [inactiveCam, setInactiveCam] = useGlobalState('inactiveCam');

  const privacyLink = 'https://columbiapicturesaquaverse.com/en/privacy-policy'

  const termOfUseLink = 'https://columbiapicturesaquaverse.com/en/terms-of-use'

  const parkRegularLink = 'https://columbiapicturesaquaverse.com/en/park-rules-regulations'



  // ภาษา
  const { t } = useTranslation();
  return (
    <>
      {inactiveCam ?
        <div style={{ pointerEvents: inactiveCam ? 'none' : 'inherit' }}>
          <div className="d-none d-lg-block">
            <footer className="border-0">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="col-footer border-right">
                      <img src={logo} alt="" className="logo" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-footer border-right">
                      <div className="wrapper pt-3">
                        <p className="mb-0 font-10 weight-700">
                          {t('columbia_pictures_aquaverse')}
                        </p>
                        <p className="mb-0 font-8 color-grey">
                          {t('address')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-footer border-right">
                      <div className="wrapper ">
                        <p className="mb-2 font-8 color-grey">{t('email_us')}</p>
                        <p className="mb-0 font-10 weight-700">
                          <a>
                            info@columbiapicturesaquaverse.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-footer border-right">
                      <div className="wrapper ">
                        <p className="mb-2 font-8 color-grey">{t('call_us')}</p>
                        <p className="mb-0 font-10 weight-700">

                          +66 (0) 33 004 999
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-footer">
                      <div className="wrapper ">
                        <p className="mb-2 font-8 color-grey">{t('follow_us')}</p>
                        <ul className="list-unstyled list-inline mb-0 list-social">
                          <li className="list-inline-item">
                            <Line className="logo" />
                          </li>
                          <li className="list-inline-item">
                            <Youtube className="logo" />
                          </li>
                          <li className="list-inline-item">
                            <Facebook className="logo" />
                          </li>
                          <li className="list-inline-item">
                            <Instagram className="logo" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>

            <div className="p-4 bg-black text-white text-center">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-auto text-start">
                    <p className="font-8 mb-0">
                      @ 2022 Columbia Pictures Location Based Entertainment Inc. All
                      Rights Reserved.
                    </p>
                  </div>
                  <div className="col text-end">
                    <ul className="list-unstyled list-inline mb-0 list-footer-menu">
                      <li className="list-inline-item">
                        <a href={privacyLink} target="_blank" >
                          {t('privacy_policy')}
                        </a>
                      </li>
                      <li className="list-inline-item">|</li>
                      {/* <li className="list-inline-item">
                        <a href="#" >
                          {t('legal_notices')}
                        </a>
                      </li>
                      <li className="list-inline-item">|</li> */}
                      <li className="list-inline-item">
                        <a href={termOfUseLink} target="_blank">
                          {t('terms_of_use')}
                        </a>
                      </li>
                      <li className="list-inline-item">|</li>
                      <li className="list-inline-item">
                        <a href={parkRegularLink} target="_blank">
                          {t('rules_and_regulations')}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-block d-lg-none">
            <footer className="border-0 p-0">
              <div className="p-4 text-center border-top">
                <h6 className="font-20 weight-800 mb-0">{t('need_help')}</h6>
                <p className="font-14 weight-600">
                  {t('send_us')}
                  {/* <a
            href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;danita@cartoonNetworkamazone.com.com&amp;body=Hello , I have a problem with..."
            target="_blank" rel="noopener noreferrer"
          > */}{t('enquiry')}
                  {t('or_callus')}
                  <a >+66(0)33 004 999</a>
                </p>
              </div>
              <div className="p-4 bg-black text-white text-center">
                <div className="container">
                  <img src={logoinvert} className="logo mb-4" />
                  <p className="font-10 mb-0">
                    @ 2022 Columbia Pictures Location Based Entertainment Inc.
                    <br />
                    All Rights Reserved.
                  </p>
                </div>
              </div>
            </footer>
          </div>
        </div>
        :
        <div>
          <div className="d-none d-lg-block">
            <footer className="border-0">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col">
                    <div className="col-footer border-right">
                      <img src={logo} style={{ width: 'auto' }} alt="" className="logo" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-footer border-right">
                      <div className="wrapper pt-3">
                        <p className="mb-0 font-10 weight-700">
                          {/* {t('columbia_pictures_aquaverse')} */}
                          {t('columbia_pictures_aquaverse')}
                        </p>
                        <p className="mb-0 font-8 color-grey">
                          {t('address')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-footer border-right">
                      <div className="wrapper ">
                        <p className="mb-2 font-8 color-grey">
                          {t('email_us')}
                        </p>
                        <p className="mb-0 font-10 weight-700">
                          {routePath.toLocaleLowerCase() == '/megadeal' ?
                            <a href="mailto:hello@columbiapicturesaquaverse.com" target="_blank">
                              hello@columbiapicturesaquaverse.com
                            </a>
                            :
                            <a href="mailto:info@columbiapicturesaquaverse.com" target="_blank">
                              {'info@columbiapicturesaquaverse.com'}

                            </a>
                          }

                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-footer border-right">
                      <div className="wrapper ">
                        <p className="mb-2 font-8 color-grey">
                          {t('call_us')}
                        </p>
                        <p className="mb-0 font-10 weight-700">
                          <a href="tel:+6633004999">
                            {'+66 (0) 33 004 999'}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="col-footer">
                      <div className="wrapper ">
                        <p className="mb-2 font-8 color-grey">{t('follow_us')}</p>
                        <ul className="list-unstyled list-inline mb-0 list-social">
                          <li className="list-inline-item">
                            <a href="https://page.line.me/das1327d" target="_blank">
                              <Line className="logo" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="https://www.youtube.com/CNAmazone" target="_blank">
                              <Youtube className="logo" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="https://www.facebook.com/ColumbiaPicturesAquaverse" target="_blank">
                              <Facebook className="logo" />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href="https://www.instagram.com/columbiapicturesaquaverse" target="_blank">
                              <Instagram className="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>

            <div className="p-4 bg-black text-white text-center">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-auto text-start">
                    <p className="font-8 mb-0">
                      {'@ 2022 Columbia Pictures Location Based Entertainment Inc. All Rights Reserved.'}
                    </p>
                  </div>
                  <div className="col text-end">
                    <ul className="list-unstyled list-inline mb-0 list-footer-menu">
                      <li className="list-inline-item">
                        <a href={privacyLink} target="_blank" >
                          {t('privacy_policy')}
                        </a>
                      </li>
                      <li className="list-inline-item">|</li>
                      {/* <li className="list-inline-item">
                        <a href="#" >
                          {t('legal_notices')}
                        </a>
                      </li>
                      <li className="list-inline-item">|</li> */}
                      <li className="list-inline-item">
                        <a href={termOfUseLink} target="_blank" >
                          {t('terms_of_use')}
                        </a>
                      </li>
                      <li className="list-inline-item">|</li>
                      <li className="list-inline-item">
                        <a href={parkRegularLink} target="_blank" >
                          {t('rules_and_regulations')}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-block d-lg-none">
            <footer className="border-0 p-0">
              <div className="p-4 text-center border-top">
                <h6 className="font-20 weight-800 mb-0">{t('need_help')}</h6>
                <p className="font-14 weight-600">
                  {t('send_us')}
                  {/* <a
                href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;danita@cartoonNetworkamazone.com.com&amp;body=Hello , I have a problem with..."
                target="_blank" rel="noopener noreferrer"
              > */}
                  {routePath.toLocaleLowerCase() == '/megadeal' ?
                    <a href="mailto:hello@columbiapicturesaquaverse.com?subject=Guest checkout inquiry letter&body=Dear Sir/Madam %0A%0A I would like learn more about check out . Could you please send me more details at the earliest? %0D%0A Thank you for your prompt response. %0A%0A Yours sincerely," target="_blank" rel="noopener noreferrer">
                      {t('enquiry')}
                    </a>
                    :
                    <a href="mailto:online@columbiapicturesaquaverse.com?subject=Guest checkout inquiry letter&body=Dear Sir/Madam %0A%0A I would like learn more about check out . Could you please send me more details at the earliest? %0D%0A Thank you for your prompt response. %0A%0A Yours sincerely," target="_blank" rel="noopener noreferrer">
                      {t('enquiry')}
                    </a>
                  }


                  {t('or_callus')}
                  <a href="tel:+6633004999">+66(0)33 004 999</a>
                </p>
              </div>
              <div className="p-4 bg-black text-white text-center">
                <div className="container">
                  <img src={logoinvert} className="logo mb-4" />
                  <p className="font-10 mb-0">
                    @ 2022 Columbia Pictures Location Based Entertainment Inc.
                    <br />
                    All Rights Reserved.
                  </p>
                </div>
              </div>
            </footer>
          </div>
        </div>
      }

    </>

  );
};

export default Footer;
