import React, { useCallback, useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";

//Image
import logo from "../component/img/logo-xl.png";
import PicPasscode from "../component/img/Passcodechanged.png";
import { ReactComponent as Close } from "../component/img/close.svg";
import { ReactComponent as Passcode } from "../component/img/Passcode.svg";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";

//css
import "../component/css/style.css";
import "../component/css/custom.css";
import { baseLink, apiLink, coreApi } from '../constants/DataPublic';
import { useGlobalState } from '../global'

const ChangePass = (props) => {

    const { language } = props
    const navigate = useNavigate();
    const [routePath, setRoutePath] = useGlobalState('route');
    const [canChange, setCanChange] = useState(false)
    const [activeConfirm, setActiveConfirm] = useState(false)
    const [message, setMessage] = useState("")

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    useEffect(() => {
        if (user != null) {
            console.log("Change Passcode");
        } else {
            navigate("/signin")
        }
    }, [])

    const onNumerPWKeyDown = (event) => {
        if (!/[0-9]/.test(event.key) && event.key != 'Backspace') {
            event.preventDefault();
            console.log("event", event)
        }

    };

    const [OldpasswordType, setOldPasswordType] = useState("password");
    const [OldpasswordInput, setOldPasswordInput] = useState("");


    const toggleOldPassword = () => {
        if (OldpasswordType === "password") {
            setOldPasswordType("number")
            return;
        } setOldPasswordType("password")
    }

    const [NewpasswordType, setNewPasswordType] = useState("password");
    const [NewpasswordInput, setNewPasswordInput] = useState("");

    const toggleNewPassword = () => {
        if (NewpasswordType === "password") {
            setNewPasswordType("number")
            return;
        } setNewPasswordType("password")
    }

    const [CFpasswordType, setCFPasswordType] = useState("password");
    const [CFpasswordInput, setCFPasswordInput] = useState("");

    const toggleCFPassword = () => {
        if (CFpasswordType === "password") {
            setCFPasswordType("number")
            return;
        } setCFPasswordType("password")
    }

    useEffect(() => {
        if (OldpasswordInput.length === 6) {
            checkPasscode()
        } else {
            setCanChange(false)
        }
    }, [OldpasswordInput])

    useEffect(() => {
        if (NewpasswordInput.length === 6 && CFpasswordInput.length === 6) {
            setActiveConfirm(true)
        } else {
            setMessage("")
            setActiveConfirm(false)
        }

    }, [NewpasswordInput, CFpasswordInput])

    async function checkPasscode() {

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", "accept-language": language },
            body: JSON.stringify({
                UserId: user.userId,
                Passcode: OldpasswordInput,
                PushKey: "",
                DeviceType: "",
                DeviceKey: ""
            }),
        };
        console.log(requestOptions)
        await fetch(
            coreApi + "/afcl/Account/checkpasscode",
            requestOptions
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                const items = data;
                console.log(items);
                if (items["Success"] === true) {
                    console.log("Check Success = true");
                    setCanChange(true)
                    setMessage("")
                } else {
                    console.log("Check Success = false");
                    setCanChange(false)
                    setMessage(items["Message"])
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
                // alert('Error: API /afcl/Account/checkpasscode ', error)
                setCanChange(false)
            });
    }

    async function changePasscode() {

        if (NewpasswordInput !== CFpasswordInput) {
            console.log("New Password Not Match!!")
            setMessage("passcode_notmatch")
        } else {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", "accept-language": language },
                body: JSON.stringify({
                    UserId: user.userId,
                    OldPassCode: OldpasswordInput,
                    NewPassCode: NewpasswordInput
                }),
            };
            console.log(requestOptions)
            await fetch(
                coreApi + "/afcl/Account/changepasscode/save",
                requestOptions
            )
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    const items = data;
                    console.log(items);
                    if (items["Success"] === true) {
                        console.log("Change Success = true");
                        sessionStorage.clear()
                        setShowPopupChangePass(true)
                    } else {
                        console.log("Change Success = false");
                    }
                })
                .catch((error) => {
                    console.log("Error: ", error);
                    // alert('Error: API /afcl/Account/changepasscode/save ', error)
                });
        }
    }

    /** Send Email Forget **/
    const [showPopupChangePass, setShowPopupChangePass] = useState(false);
    const CloseShowPopupChangePass = () => setShowPopupChangePass(false);

    // ภาษา
    const { t } = useTranslation();

    return (
        <div>
            <div className="page page-steps">
                <div className="section py-5">

                    <div className="container">
                        <div className="row">
                            <div className="logosignin">
                                <img src={logo} alt="" id="logosignin" />
                            </div>
                            <div className="col-xl-5 col-lg-6 col-md-7 col-sm-12 box-body box-border box-signin signinradius" style={{ margin: "0 auto" }}>

                                <from>
                                    <div className="mb-3">
                                        <h2 className="main-title mb-3">{t('change_passcode')}</h2>
                                        <p>{t('passcode_number')}</p>

                                        <div className="py-5">
                                            <div className="mb-3">
                                                <label className="form-label text-color">{t('old_passcode')}* </label>
                                                {OldpasswordType != "password" && (
                                                    <div className="input-group boxInputPass mb-3">
                                                        <input type="number" onKeyDown={onNumerPWKeyDown} onChange={(e) => setOldPasswordInput(e.target.value.replace(/\D/g, ""))} value={OldpasswordInput} className="form-control boxborderPass" placeholder={t('old_passcode')} inputMode='numeric' pattern="[0-9]*"
                                                            onInput={(e) => {
                                                                if (e.target.value.length > e.target.maxLength)
                                                                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                                                            }}
                                                            maxlength={6}
                                                        />
                                                        <div className="input-group-prepend boxborderPass">
                                                            <span onClick={toggleOldPassword}><FiEyeOff /></span>
                                                        </div>
                                                    </div>
                                                )}
                                                {OldpasswordType === "password" && (
                                                    <div className="input-group boxInputPass mb-3">
                                                        <input type="password" onKeyPress={onNumerPWKeyDown} onChange={(e) => setOldPasswordInput(e.target.value.replace(/\D/g, ""))} value={OldpasswordInput} className="form-control boxborderPass" placeholder={t('old_passcode')} maxLength={6} inputMode='numeric' pattern="[0-9]*" />
                                                        <div className="input-group-prepend boxborderPass">
                                                            <span onClick={toggleOldPassword}><FiEye /></span>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label text-color">{t('new_passcode')}* </label>
                                                {NewpasswordType != "password" && (
                                                    <div className="input-group boxInputPass mb-3">
                                                        <input type="number" onKeyDown={onNumerPWKeyDown} onChange={(e) => setNewPasswordInput(e.target.value.replace(/\D/g, ""))} value={NewpasswordInput} className="form-control boxborderPass" placeholder={t('new_passcode')} disabled={!canChange} inputMode='numeric' pattern="[0-9]*"
                                                            onInput={(e) => {
                                                                if (e.target.value.length > e.target.maxLength)
                                                                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                                                            }}
                                                            maxlength={6}
                                                        />
                                                        <div className="input-group-prepend boxborderPass">
                                                            <span onClick={toggleNewPassword}><FiEyeOff /></span>
                                                        </div>
                                                    </div>
                                                )}
                                                {NewpasswordType === "password" && (
                                                    <div className="input-group boxInputPass mb-3">
                                                        <input type="password" onKeyDown={onNumerPWKeyDown} onChange={(e) => setNewPasswordInput(e.target.value.replace(/\D/g, ""))} value={NewpasswordInput} className="form-control boxborderPass" placeholder={t('new_passcode')} maxLength={6} inputMode='numeric' pattern="[0-9]*" disabled={!canChange} />
                                                        <div className="input-group-prepend boxborderPass">
                                                            <span onClick={toggleNewPassword}><FiEye /></span>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label text-color">{t('confirm_new_passcode')}* </label>
                                                {CFpasswordType != "password" && (
                                                    <div className="input-group boxInputPass mb-3">
                                                        {/* .replace(/^0+/g, "") */}
                                                        <input type="number" onKeyDown={onNumerPWKeyDown} onChange={(e) => setCFPasswordInput(e.target.value.replace(/[^0-9]/gi, "") || "")} value={CFpasswordInput} className="form-control boxborderPass" placeholder={t('confirm_new_passcode')} inputMode='numeric' pattern="[0-9]*" disabled={!canChange}
                                                            onInput={(e) => {
                                                                if (e.target.value.length > e.target.maxLength)
                                                                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                                                            }}
                                                            maxlength={6}
                                                        />
                                                        <div className="input-group-prepend boxborderPass">
                                                            <span onClick={toggleCFPassword}><FiEyeOff /></span>
                                                        </div>
                                                    </div>
                                                )}
                                                {CFpasswordType === "password" && (
                                                    <div className="input-group boxInputPass mb-3">
                                                        {/* .replace(/^0+/g, "") */}
                                                        <input type="password" onKeyDown={onNumerPWKeyDown} onChange={(e) => setCFPasswordInput(e.target.value.replace(/[^0-9]/gi, "") || "")} value={CFpasswordInput} className="form-control boxborderPass" placeholder={t('confirm_new_passcode')} maxLength={6} inputMode='numeric' pattern="[0-9]*" disabled={!canChange} />
                                                        <div className="input-group-prepend boxborderPass">
                                                            <span onClick={toggleCFPassword}><FiEye /></span>
                                                        </div>
                                                    </div>
                                                )}

                                                <span className="text-danger">{t(message)}</span>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="mb-3">
                                        <Button type="button" className="btn btn-link btn-db text-capitalize" onClick={changePasscode} disabled={!activeConfirm}>{t('confirm')}</Button>
                                        <Modal show={showPopupChangePass} className="col-12 popup-changepw">
                                            <a href={baseLink + routePath + "/signin"} className="close"><Close className="popup-close" onClick={CloseShowPopupChangePass} /></a>
                                            <div className="logosignin"> <img src={logo} className="logo-popup" /></div>
                                            <div className="text-center mb-3">
                                                <img src={PicPasscode} className="img-forgetpopup" />
                                            </div>
                                            <p className="text-center text-popup-title">{t('passcode_changed')}</p>
                                            <div className="text-center"><a href={baseLink + routePath + "/signin"} className="link-lb">{t('back_to_signin')}</a></div>
                                        </Modal>
                                    </div>
                                </from>
                                <div className="text-center weight-500"><a href={baseLink + routePath + "/signin"} className="link-lb">{t('back')}</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePass
