import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { apiLink } from '../../constants/DataPublic'

import { SubTicket } from './SubTicket';
import { SubTicketItem } from './SubTicketItem';

import Pass from "../../component/img/pass.png";

import TermIcon from "../../component/img/term.png";
import { ReactComponent as Verified } from "../../component/img/check-green.svg";


const Step1 = (props) => {
  const { packageTicket } = props
  const { step } = props
  const { currentStep } = props
  const { packageCode } = props
  const { cartUpdate } = props
  const [packageSelected, setPackageSelected] = useState({})

  let myCart = sessionStorage.getItem('MyCart')
  myCart = JSON.parse(myCart)

  useEffect(() => {
    if (step == '1') {
      var dataLayer = window.dataLayer || [];
      dataLayer.push({
        'event': 'checkoutStep',
        'ecommerce': {
          'checkout': {
            'actionField': { 'step': 1, 'option': '' }
          }
        }
      });
      console.log("dataLayer step", dataLayer, step)
    }

    console.log("Step1: ", packageCode, currentStep, packageTicket);
    if (packageTicket.length > 0) {
      let sPackage = packageTicket.filter(e => e.PackageCode === packageCode)[0]
      setPackageSelected(sPackage)
      packageTicket.sort((a, b) => (a.PackageSeq > b.PackageSeq ? 1 : -1))
    }
    console.log("packageTicketSeq", packageTicket)
  }, [packageTicket])

  const onAddToCart = (itemCart, isEdit) => {
    props.onAddToCart(itemCart, isEdit)
  }

  // ภาษา
  const { t } = useTranslation();

  return (
    // <h1>xxx</h1>
    <div className="col-main">
      <div className="right-wrapper scrollable">
        {Object.keys(packageTicket).length > 0 && (
          <div className="right-wrapper scrollable">
            {Object.keys(packageTicket).length > 0 && (
              <div className="box box-main mb-5">

                {packageSelected != null && Object.keys(packageSelected).length > 0 && !packageSelected.IsSaleByItem && (
                  <SubTicket flip={true} itemList={packageSelected} currentStep={currentStep} cartUpdate={cartUpdate} onAddToCart={onAddToCart} />
                )}
                {packageSelected != null && Object.keys(packageSelected).length > 0 && packageSelected.IsSaleByItem && (
                  <SubTicketItem itemList={packageSelected} currentStep={currentStep} cartUpdate={cartUpdate} onAddToCart={onAddToCart} />
                )}

                {packageTicket.map((e, index) => {
                  let selectedPackageCode = ""
                  if (packageSelected != null) {
                    selectedPackageCode = packageSelected.PackageCode
                  }
                  if (!e.IsSaleByItem && e.PackageCode !== selectedPackageCode) {
                    return <SubTicket key={index} flip={true} currentStep={currentStep} cartUpdate={cartUpdate} itemList={e} onAddToCart={onAddToCart} />
                  } else if (e.IsSaleByItem && e.PackageCode !== selectedPackageCode) {
                    return <SubTicketItem key={index} currentStep={currentStep} cartUpdate={cartUpdate} itemList={e} onAddToCart={onAddToCart} />
                  }
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default Step1;