import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { apiLink, coreApi } from '../constants/DataPublic'
import useLogin from '../customHook/useLogin'
import { useGlobalState } from '../global'
import { ReactComponent as Close } from '../component/img/close.svg';
import { Modal } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as Google } from "../component/img/btn-google.svg";

import "../component/css/style.css";
import "../component/css/custom.css";

const GGLogin = (props) => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  let { getUserCart } = useLogin({ lang: '' })
  const [needRegist, setNeedRegist] = useState(false)
  const [routePath, setRoutePath] = useGlobalState('route');
  const [alertText, setAlertText] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { language } = props
  const { ggtext } = props


  let itCrossCam = sessionStorage.getItem('itCrossCampaign');
  itCrossCam = JSON.parse(itCrossCam);

  let myCart = sessionStorage.getItem('MyCart')
  myCart = JSON.parse(myCart)

  const handleFailure = (res) => {
    console.log("LOGIN FAILED! res: ", res);
  };

  const handleLogin = async (res) => {
    console.log("LOGIN SUCCESS! res: ", res);
    await fetch("https://www.googleapis.com/oauth2/v3/userinfo", { method: "GET", headers: { "Authorization": res.token_type + ' ' + res.access_token }, }).then(function (response) {
      return response.json();
    }).then(async function (data) {
      console.log(data)
      let ggUser = data;
      props.getGGUser(ggUser)

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          "Provider": "Google",
          "ProviderKey": ggUser.sub,
          "Email": ggUser.email,
          "PushKey": "",
          "DeviceType": "",
          "DeviceKey": ""
        }),
      };
      await fetch(coreApi + "/afcl/Account/external/checklogin", requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          const items = data;
          if (items["Result"].NeedRegister) {
            setNeedRegist(true)
          } else {
            props.setUserCode(items["Result"].User.UserCode)
            let userLogin = {
              fName: items["Result"].User.FirstName,
              lName: items["Result"].User.LastName,
              email: items["Result"].User.Email,
              userId: items["Result"].User.UserId,
              userCode: items["Result"].User.UserCode,
              photo: items["Result"].User.UserPicture,
              isLogin: true,
              channelCode: items["Result"].ChannelCode
            }
            sessionStorage.setItem('UserLogin', JSON.stringify(userLogin))
            if (myCart && myCart.length > 0) {
              navigate("/ticket/" + myCart[0].PackageCode + "/5")
            } else {
              navigate("/")
            }
            // if (itCrossCam) {
            //   window.location.href = '/';
            // } else {
            //   navigate("/ticket/" + myCart[0].PackageCode + "/5")
            // }
          }
          console.log("items", items)
          props.getNeedRegist(items["Result"].NeedRegister)
        })
        .catch((error) => {
          console.log("Error: ", error);
          setShowAlert(true)
          setAlertText('login_fail')
          // alert('Error: API /afcl/Account/external/checklogin ', error)
        });
    });
  };

  const Login = useGoogleLogin({
    onSuccess: (res) => handleLogin(res),
    onFailure: (err) => handleFailure(err),
  })

  return (
    <div>
      {/* <a href={itCrossCam ? baseLink + '/' : (baseLink + routePath == "" ? '/' : baseLink + routePath)}> */}
      <button onClick={() => Login()} className="btn btn-link btnloginsocial btnloginsocial-guest">
        <span className="picloginsocial"><Google /></span><span className="textloginsocial">{t(ggtext)}</span>
      </button>
      {/* </a> */}

      <Modal show={showAlert} className="col-12 cart-placeorder">
        <a href="#" className="close">
          <Close className="popup-close" onClick={() => setShowAlert(false)} />
        </a>
        <h5 className="text-center text-danger py-5">{t(alertText)}</h5>
      </Modal>
    </div>
  );
};

export default GGLogin;
