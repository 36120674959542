import React, { useEffect, useState } from 'react'
import cookiesImg from '../img/cookies.jpg'
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Cookies from "js-cookie";
import { useGlobalState } from '../../global';

const CookiesPolicy = (props) => {
    const { t } = useTranslation();

    const [analytics, setAnalytic] = useState(true);
    const [marketing, setMarketing] = useState(true);
    const [acceptCookies, setAcceptCookies] = useGlobalState('cookiesConsent');

    console.log("analytics", analytics);
    console.log("marketing", marketing);

    useEffect(() => {
        if (Cookies.get("AFCL-analytic-cookie") == undefined && Cookies.get("AFCL-marketing-cookie") == undefined) {
            setAnalytic(true)
            setMarketing(true)
            // Cookies.set("AFCL-analytic-cookie", true);
            // Cookies.set("AFCL-marketing-cookie", true);
        } else {
            setAnalytic(JSON.parse(Cookies.get("AFCL-analytic-cookie")))
            setMarketing(JSON.parse(Cookies.get("AFCL-marketing-cookie")))
        }
    }, [])

    const onChangeAnalytics = (event) => {
        setAnalytic(event.target.checked)
    }

    const onChangeMarketing = (event) => {
        setMarketing(event.target.checked)
    }

    const onAccept = () => {
        Cookies.set("AFCL-analytic-cookie", analytics);
        Cookies.set("AFCL-marketing-cookie", marketing);
        Cookies.set("AFCL-cookie-consent", true);
        setAcceptCookies(true);
    }

    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 50,
        height: 20,
        padding: 0,
        display: 'flex',
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(29px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
                transform: 'translateX(29px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#98E000',
                },
            },
            '&.Mui-disabled': {
                color: '#fff !important',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: '#34995C',
                    cursor: 'not-allowed',
                },
            }
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 15,
            height: 15,
            borderRadius: 8,
            transition: theme.transitions.create(['width'], {
                duration: 200,
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 18 / 2,
            opacity: 1,
            backgroundColor:
                theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
            boxSizing: 'border-box',
        },
    }));

    return (
        <div style={{
            marginTop: '80px',
            padding: '30px'
        }}>
            <h5 className="mb-2 lh-1">{t('cookies_policy')}</h5>
            <p> {t('cookies_policy_d')} </p>
            <h5 className="mb-2 lh-1">{t('cookies_what_is_cookies')}</h5>
            <p> {t('cookies_what_is_cookies_d')} </p>
            <h5 className="mb-2 lh-1"> {t('cookies_how_does_company')}</h5>
            <p> {t('cookies_how_does_company_d')}</p>
            <div className='p-4'>
                {/* <img style={{ maxWidth: '100%' }} src={cookiesImg} alt='cookies-img' /> */}
                <h5>{t('we_use_cookies')}</h5>
                <p>{t('use_cookie_enhance')}</p>
                <h5>{t('preference_setting')}</h5>
                <div className='p-4'>
                    <div className='row'>
                        <div className='col-10'>
                            <h5>{t('preference_setting')}</h5>
                            <p>{t('necessary_cookies')} </p>
                        </div>
                        <div className='col-2 d-flex flex-column justify-content-center align-items-center'>
                            <p style={{ fontWeight: '700' }}>{t('always_on')}</p>
                            <FormGroup>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <AntSwitch disabled defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                </Stack>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-10'>
                            <h5>{t('analytics')}</h5>
                            <p>{t('analytics_cookies')}</p>
                        </div>
                        <div className='col-2 d-flex justify-content-center align-items-center'>
                            <FormGroup>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <AntSwitch checked={analytics} onChange={onChangeAnalytics} inputProps={{ 'aria-label': 'ant design' }} />
                                </Stack>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-10'>
                            <h5>{t('marketing')}</h5>
                            <p>{t('marketing_cookies')}</p>
                        </div>
                        <div className='col-2 d-flex justify-content-center align-items-center'>
                            <FormGroup>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <AntSwitch checked={marketing} onChange={onChangeMarketing} inputProps={{ 'aria-label': 'ant design' }} />
                                </Stack>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='text-right row py-2'>
                        <div className='col-0 col-md-10 col-xl-11'></div>
                        <div className='col-12 col-md-2 col-xl-1'>
                            <button className='btn btn-main w-100' onClick={onAccept}>
                                {t('agree')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <h5 className="mb-2 lh-1">{t('cookies_use_by_company')}</h5>
            <p> {t('cookies_use_by_company_d')} </p>
            <h5 className="mb-2 lh-1">{t('cookies_the_purposes')} </h5>
            <div className='mb-3'>
                <p className='mt-2 mb-2'>{t('cookies_Strictly')}</p>
                {t('cookies_Strictly_d')}
                <p className='mt-2 mb-2'>  {t('cookies_performance')}</p>
                {t('cookies_performance_d')}
                <p className='mt-2 mb-2'>{t('cookies_functional')}</p>
                {t('cookies_functional_d')}
                <p className='mt-2 mb-2'>{t('cookies_targeting')}</p>
                {t('cookies_targeting_d')}
            </div>
            <table className='cookies-table' style={{ width: '100%', tableLayout: 'fixed' }}>
                <tr style={{ height: '65px', background: '#DEDEDE' }}>
                    <th width='25%'> {t('cookies_table_type')} </th>
                    <th width='25%'> {t('cookies_table_name')}  </th>
                    <th width='25%'> {t('cookies_table_provider')}  </th>
                    <th width='25%'> {t('cookies_table_object')}  </th>
                </tr>
                <tr>
                    <td rowSpan={4}>{t('cookies_table_strictly')}</td>
                    <td>ARRAffinity</td>
                    <td>Azure</td>
                    <td style={{ padding: '10px' }}>{t('cookies_table_azure_providing_services')}</td>
                </tr>
                <tr>
                    {/* <td></td> */}
                    <td>ARRAffinitySameSite</td>
                    <td>Azure</td>
                    <td style={{ padding: '10px' }}>{t('cookies_table_used_for_load_balancing')}</td>
                </tr>
                <tr>
                    {/* <td></td> */}
                    <td>__hssrc</td>
                    <td>Hubspot</td>
                    <td style={{ padding: '10px' }}>{t('cookies_table_used_when_the_cookie')}</td>
                </tr>
                <tr>
                    {/* <td></td> */}
                    <td>__hssc</td>
                    <td>Hubspot</td>
                    <td style={{ padding: '10px' }}>{t('cookies_table_track_sessions')}</td>
                </tr>


                <tr>
                    <td rowSpan={4}>{t('cookies_table_analytical_performance')}</td>
                    <td>_ga_*</td>
                    <td>Google Analytics</td>
                    <td style={{ padding: '10px' }}>{t('cookies_table_to_store_and_count')}</td>
                </tr>
                <tr>
                    {/* <td></td> */}
                    <td>_ga</td>
                    <td>Google Analytics</td>
                    <td style={{ padding: '10px' }}>{t('cookies_table_calculate_visitor')}</td>
                </tr>
                <tr>
                    {/* <td></td> */}
                    <td>_gid</td>
                    <td>Google Analytics</td>
                    <td style={{ padding: '10px' }}>{t('cookies_table_store_information')}</td>
                </tr>
                <tr>
                    {/* <td></td> */}
                    <td>_gat_UA-*</td>
                    <td>Google Analytics</td>
                    <td style={{ padding: '10px' }}>{t('cookies_table_for_tracking')}</td>
                </tr>


                <tr>
                    <td rowSpan={6}>{t('cookies_table_targeting')}</td>
                    <td>_gcl_au</td>
                    <td>Google AdSense</td>
                    <td style={{ padding: '10px' }}>{t('cookies_table_used_to_view')}</td>
                </tr>
                <tr>
                    {/* <td></td> */}
                    <td>test_cookie</td>
                    <td>.doubleclick.net</td>
                    <td style={{ padding: '10px' }}>{t('cookies_table_to_check_whether')}</td>
                </tr>
                <tr>
                    {/* <td></td> */}
                    <td>IDE</td>
                    <td>.doubleclick.net</td>
                    <td style={{ padding: '10px' }}>{t('cookies_table_storing')}</td>
                </tr>
                <tr>
                    {/* <td></td> */}
                    <td>bcookie</td>
                    <td>LinkedIn</td>
                    <td style={{ padding: '10px' }}>{t('cookies_table_Identify')}</td>
                </tr>
                <tr>
                    {/* <td></td> */}
                    <td>li_sugr</td>
                    <td>LinkedIn</td>
                    <td style={{ padding: '10px' }}>{t('cookies_table_to_collect_user_behavior')}</td>
                </tr>
                <tr>
                    {/* <td></td> */}
                    <td>_fbp</td>
                    <td>Facebook</td>
                    <td style={{ padding: '10px' }}>{t('cookies_table_to_display_ads_when_either')}</td>
                </tr>
            </table>
            <h5 className="mb-2 lh-1">{t('cookies_manage_cookies')}</h5>
            <div className='mb-3'>
                {t('cookies_manage_cookies_d')}
                <p className='mt-2 mb-2'>
                    <a href='https://support.google.com/accounts/answer/61416?hl=en&co=GENIE.Platform%3DDesktop' target='_blank'>
                        {t('cookies_setting_chrome')}
                    </a>
                </p>
                <p className='mt-2 mb-2'>
                    <a href='https://support.mozilla.org/en-US/kb/websites-say-cookies-are-blocked-unblock-them' target='_blank'>
                        {t('cookies_setting_firefox')}
                    </a>
                </p>
                <p className='mt-2 mb-2'>
                    <a href='https://help.apple.com/safari/mac/8.0/en.lproj/sfri11471.html' target='_blank'>
                        {t('cookies_setting_ios')}
                    </a>
                </p>
                <p className='mt-2 mb-2'>
                    <a href='https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d' target='_blank'>
                        {t('cookies_setting_ie')}
                    </a>
                </p>
                <p className='mt-2 mb-2'>
                    <a href='https://support.microsoft.com/en-us/microsoft-edge/view-cookies-in-microsoft-edge-a7d95376-f2cd-8e4a-25dc-1de753474879' target='_blank'>
                        {t('cookies_setting_edge')}
                    </a>
                </p>
                <p className='mt-1 mb-1'>
                    {t('cookies_please_note')}
                </p>
                <p className='mt-1 mb-1'>
                    {t('cookies_the_company_will_not')}
                </p>
                <p className='mt-1 mb-1'>
                    {t('cookies_for_more')} 

                    <a href='https://www.aboutcookies.org/how-to-delete-cookies' target='_blank'>
                        https://www.aboutcookies.org/how-to-delete-cookies
                    </a>
                </p>
            </div>
            <h5 className="mb-2 lh-1">{t('cookies_link_to_other')}</h5>
            <p>{t('cookies_link_to_other_d')}</p>
            <h5 className="mb-2 lh-1">{t('cookies_announcement')}</h5>
            <p>{t('cookies_announcement_d')}</p>
            <h5 className="mb-2 lh-1">{t('cookies_contact_us')}</h5>
            <div>
                {t('cookies_columbia')} <br />
                {t('cookies_address')} <br />
            </div>
        </div>
    )
}

export default CookiesPolicy