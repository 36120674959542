import React, { useEffect, useState } from 'react';
import { Markup } from 'interweave';
import { useTranslation } from 'react-i18next';
import { ImageLoader } from "react-image-and-background-image-fade";
import TermIcon from '../../component/img/info.png';
import noImg from '../../component/img/no_image.png';
import ReactCardFlip from "react-card-flip";
import { ReactComponent as Verified } from '../../component/img/check-green.svg';
import { useMediaQuery } from 'react-responsive'
import { packageTypeTicket } from '../../constants/DataPublic';

export const AddSubTicketFlip = (props) => {
    const { currentStep } = props
    const { picturePath } = props
    const { packageTypeTicket } = props
    const { itemList } = props
    const { itemQty } = props
    const { netPrice } = props
    const { selectedI } = props
    const { subTotalI } = props
    const { editI } = props
    const { selectedDate } = props
    const { selectedTime } = props
    const { requiredDate } = props

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 991px)'
    })

    const [isFlipped, setIsFlipped] = useState(false)

    const setItemQty = (item) => {
        props.setItemQty(item);
    };

    const addToCart = (item, isEdit) => {
        props.addToCart(item, isEdit);
    };

    const setSelectedI = (item) => {
        props.setSelectedI(item);
    };

    const setSelectedDate = (item) => {
        props.setSelectedDate(item);
    };

    const setRequiredDate = (item) => {
        props.setRequiredDate(item);
    };

    const setRequiredTime = (item) => {
        props.setRequiredTime(item);
    };

    const handleClick = (e) => {
        e.preventDefault()
        setIsFlipped(!isFlipped)
    }

    const { t } = useTranslation();

    return (
        <>
            <div className="col-lg-6 ">
                {/** To Flip */}
                <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                    {/** ด้านหน้า */}
                    <div>
                        <img src={picturePath} className="img-fluid w-100 d-block d-lg-none mb-3" />
                        <div className="box box-border mb-0">
                            <div className="box-body ">
                                <div className="row">
                                    <div className="col-7 desc">
                                        {itemList.IsAddonDiscount && (
                                            <div className="d-none d-lg-block">
                                                <span className="bubble mb-3">
                                                    3rd Ticket at
                                                    <br />
                                                    50% Discount
                                                </span>
                                            </div>
                                        )}
                                        <div className="d-block d-lg-none">
                                            <p className>
                                                {/* <Markup content={itemList.PackageDescription} /> */}
                                            </p>
                                        </div>
                                    </div>
                                    {/* {itemList.PackageTypeCode === packageTypeTicket && (
                                        <div className="col-6 desc">
                                            <p className="font-14 mb-0">
                                                <strong>Fabulous</strong>
                                            </p>
                                            <Markup content={itemList.PackageDescription} />
                                        </div>
                                    )} */}
                                    {!isDesktopOrLaptop && (
                                        <div className="col-6 desc">
                                            <p className="font-14 mb-2">
                                                <strong>{t('overview')} :</strong>
                                            </p>
                                            {itemList.PackageItemLists.map((e, i) => {
                                                return (
                                                    <p >
                                                        <Markup key={i} content={e.ItemType == 'Ticket' ? '● ' + e.Qty + ' ' + e.ItemName : '+ ' + e.Qty + ' ' + e.ItemName} />
                                                    </p>
                                                );
                                            })}
                                            {/* <Markup content={itemList.PackageDescription} /> */}
                                        </div>
                                    )}
                                    {itemList.IsDiscount && (
                                        <div className="col-5 text-end">
                                            <p className="font-20 weight-700 color-orange mb-0 lh-1">
                                                {netPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                                            </p>
                                            <p className="oldPrice">
                                                {itemList.PackagePrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                                            </p>
                                            {itemList.IsAddonDiscount && (
                                                <div className="d-block d-lg-none">
                                                    <span className="bubble mb-3">
                                                        3rd Ticket at
                                                        <br />
                                                        50% Discount
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {!itemList.IsDiscount && (
                                        <div className="col-5 text-end">
                                            <p className="font-20 weight-700 mb-3 lh-1">
                                                {netPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                                            </p>
                                        </div>
                                    )}
                                    <div className="col-12">
                                        <p className="color-orange font-10 mb-0 weight-700">
                                            <em>{itemList.PackageDescription}</em>
                                            {/* <em>{t('ticket_expire')} </em> */}
                                            {/* <em>{itemList.PackageExpireMessage}</em> */}
                                        </p>
                                    </div>
                                    <div className="col-12">
                                        <hr className="mt-2 mb-3" />
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-6 desc">
                                        <p className="font-10 mb-0">{t('quantity')}</p>
                                    </div>
                                    <div className="col-6 text-end">
                                        <div className="input-group ms-auto">
                                            <span className="input-group-btn">
                                                <button
                                                    type="button"
                                                    className={`btn  btn-number minus ${selectedI}`}
                                                    data-type="minus"
                                                    data-field="quant[1]"
                                                    onClick={(e) => {
                                                        if (itemQty > 1) setItemQty((itemQty) => itemQty - 1);
                                                        else setItemQty(1);
                                                    }}
                                                >
                                                    <span className="material-icons">remove</span>
                                                </button>
                                            </span>
                                            <input type="text" name="quant[1]" className="form-control input-number" style={{ marginLeft: '-3px' }} value={itemQty} defaultValue={1} min={1} disabled />
                                            <span className="input-group-btn">
                                                <button
                                                    type="button"
                                                    className={`btn btn-number plus ${selectedI}`}
                                                    data-type="plus"
                                                    data-field="quant[1]"
                                                    onClick={(e) => {
                                                        setItemQty((itemQty) => itemQty + 1);
                                                    }}
                                                >
                                                    <span className="material-icons">add</span>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-body ">
                            <div className="row align-items-center">
                                <div className="col-6 pe-0">
                                    <p className="font-14 weight-700 mb-0">{t('subtotal')}</p>
                                </div>
                                <div className="col-6 text-end ps-0">
                                    <p className="font-24 weight-900 color-orange mb-0">
                                        {subTotalI.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <span className="font-18">{t('thb')}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="d-block d-lg-none mb-4">
                            <p
                                className="d-flex font-14 mb-2 weight-600 text-decoration-underline"
                                data-bs-toggle="collapse"
                                href={'#term' + itemList.PackageCode}
                                role="button"
                                aria-expanded="false"
                                aria-controls="term1"
                            >
                                <img src={TermIcon} className="TermIcon" />
                                {t('terms_conditions')}{' '}
                            </p>
                            <div className="collapse" id={'term' + itemList.PackageCode}>
                                <div className="card card-body">
                                    <Markup content={itemList.PackageTermAndCondition} />
                                </div>
                            </div>
                        </div>
                        {selectedI === '' && (
                            <div className="col-12 d-">
                                <button
                                    className="btn btn-main"
                                    type="button"
                                    onClick={handleClick}
                                >
                                    {t('addtocart')}
                                </button>
                            </div>
                        )}
                        {selectedI !== '' && (
                            <span className="btn">
                                <Verified className="img- me-3" />
                                {t('addtocart')}{' '}
                            </span>
                        )}
                        {/* {!selectedI && (
                            <div className="text-center">
                                <button type="button" className="btn btn-blue btn-not-full text-white mt-auto mx-auto align-self-start flipButton" onClick={handleClick}>{t('select')}</button>
                            </div>
                        )} */}
                    </div>


                    {/** ด้านหลัง */}
                    <div className="back">
                        <div className="card-header d-flex py-3">
                            <a href="#" className="color-black lh-1 m-0">
                                <span className="material-icons flipButton font-14" onClick={handleClick}>arrow_back_ios</span>
                            </a>
                        </div>

                        <div className="card-body d-flex flex-column" style={{ height: '250px' }}>
                            <h5 className="card-title mb-1 lh-1 p-2 text-center">
                                {itemList.PackageItemLists.map((e, i) => {
                                    if (e.IsVisitDate == true) {
                                        return (i == 0 ? '' + e.ItemName : '' + e.ItemName)
                                    }
                                })}
                            </h5>
                            {itemList.IsDiscount && (
                                <div className="pt-3 text-center border-top">
                                    <p className="font-24 weight-900 color-orange mb-0 lh-1">{netPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                                        <span className="font-14 weight-600 color-black">/{t('round')}</span>
                                    </p>
                                    <p className="oldPrice weight-400">{itemList.PackagePrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}</p>
                                </div>
                            )}
                            {!itemList.IsDiscount && (
                                <div className="mb-1">
                                    <p className="weight-400">{netPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}</p>
                                </div>
                            )}

                            <div className="row mb-3 pb-3 border-bottom">
                                <div className="col-4 m-auto">
                                    <p className="color-grey font-10 mb-0">{t('quantity')}</p>
                                </div>

                                <div className="col-8">
                                    <div className="input-group ms-auto">
                                        <span className="input-group-btn">
                                            <button type="button" name="quant[1]" className="btn btn-number minus" disabled={selectedI} data-type="minus" data-field="quant[1]" onClick={e => {
                                                if (itemQty > 1)
                                                    setItemQty(itemQty => itemQty - 1)
                                                else setItemQty(1)
                                            }}>

                                                <span className="material-icons">remove</span>
                                            </button>
                                        </span>

                                        <input type="text" name="quant[1]" className="form-control input-number" style={{ marginLeft: "-3px" }} value={itemQty} defaultValue={1} disabled />
                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn-number" data-type="plus" disabled={selectedI} data-field="quant[1]" onClick={e => { setItemQty(itemQty => itemQty + 1) }}>
                                                <span className="material-icons">add</span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-2 pb-3 border-bottom">
                                <div className="col-4 m-auto">
                                    <p className="color-grey font-10 mb-0">{t('visitdate')}
                                        {/* {itemList.PackageGroupTypeCode === transportPackageGroupTypeCode && (<span> *</span>)} */}
                                        {<span> *</span>}
                                    </p>
                                </div>

                                <div className="col-8">
                                    <input id="startDate" className={`form-control ${requiredDate}`} type="date" min={new Date().toISOString().split('T')[0]} value={selectedDate} disabled={selectedI} onChange={e => {
                                        const pickDate = e.target.value
                                        if (pickDate < new Date().toISOString().split('T')[0]) {
                                            let datePicker = document.getElementById("startDate")
                                            setSelectedDate("")
                                            datePicker.focus()
                                        } else {
                                            setSelectedDate(e.target.value)
                                        }
                                    }} />

                                </div>
                            </div>
                            {/* 
                            {itemList.PackageGroupTypeCode === transportPackageGroupTypeCode && (
                                <>
                                    <div className="row mb-2">
                                        <div className="col-4">
                                            <p className="color-grey font-10 mb-0">Location *</p>
                                        </div>

                                        <div className="col-8">
                                            <select className={`form-control ${requiredLocation}`} id="item_location" name="item_location" value={selectedLocation} disabled={selectedI} onChange={e => setSelectedLocation(e.target.value)}>
                                                <option value="">-</option>
                                                {
                                                    visitLocation.map((e, index) => {
                                                        return <option value={e.LocationName}>{e.LocationName}</option>
                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>

                                    <div className="row mb-0">
                                        <div className="col-4">
                                            <p className="color-grey font-10 mb-0">Time *</p>
                                        </div>

                                        <div className="col-8">
                                            <select className={`form-control ${requiredTime}`} id="transportation_schedule" name="item_time" disabled={selectedI} value={selectedTime} onChange={e => setSelectedTime(e.target.value)}>
                                                <option value="">-</option>
                                                <option disabled>1St Round</option>
                                                <option value="09:00-09:15">09:00-09:15</option>
                                                <option value="09:10-09:25">09:10-09:25</option>
                                                <option value="09:15-09:25">09:15-09:25</option>
                                                <option value="09:20-09:35">09:20-09:35</option>
                                                <option value="09:35-09:45">09:35-09:45</option>

                                                <option disabled>2nd Round</option>
                                                <option value="11:00-11:15">11:00-11:15</option>
                                                <option value="11:10-11:25">11:10-11:25</option>
                                                <option value="11:15-11:25">11:15-11:25</option>
                                                <option value="11:20-11:35">11:20-11:35</option>
                                                <option value="11:35-11:45">11:35-11:45</option>
                                            </select>
                                        </div>
                                    </div>
                                </>
                            )} */}
                        </div>


                        <div className="card-footer text-muted">
                            <div className="text-center">
                                <button type="submit" disabled={selectedI} className="btn btn-blue btn-not-full text-white mt-auto mx-auto align-self-start" onClick={() => {
                                    setRequiredDate("")
                                    setRequiredTime("")
                                    addToCart(
                                        {
                                            PackageCode: itemList.PackageCode,
                                            PackageName: itemList.PackageName,
                                            ItemCode: "",
                                            ItemName: "",
                                            Qty: itemQty,
                                            Tax: 0,
                                            ItemPrice: netPrice,
                                            ItemDiscount: itemList.IsDiscount ? itemList.DiscountPrice : 0,
                                            PriceName: itemList.PriceName,
                                            PriceUnit: itemList.PriceUnit,
                                            TotalPrice: itemQty * netPrice,
                                            VisitDate: selectedDate === "" ? null : selectedDate,
                                            VisitTime: selectedTime,
                                            VisitLocation: "",
                                            step: currentStep,
                                            status: 'normal'
                                        }, editI
                                    )
                                    if (itemQty > 0) {
                                        setSelectedI('hidden');
                                        // setCartActive('active')
                                    }
                                    setIsFlipped(!isFlipped)
                                }}>{t('select')}</button>
                            </div>
                        </div>
                    </div>
                </ReactCardFlip>
            </div>
        </>
    );
};
