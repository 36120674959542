import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { baseLink, incTax } from '../../constants/DataPublic'
import { useGlobalState } from '../../global'

const MiniCart = (props) => {

    const { packageCode } = props
    const { currentStep } = props
    const { cart } = props

    const [routePath, setRoutePath] = useGlobalState('route');
    const [subTotal, setSubTotal] = useState(0)
    const [promo, setPromo] = useState(0)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        let subT = 0
        // let savT = 0
        if (cart != null) {
            if (cart.length > 0) {
                cart.forEach(e => {
                    subT = subT + (e.ItemPrice * e.Qty)
                })
            }
            setSubTotal(subT)
        }

    }, [cart])

    useEffect(() => {
        setTotal(subTotal - promo)
    }, [subTotal])

    // ภาษา
    const { t } = useTranslation();

    return (
        <div className="mini-cart">
            <div className="mini-wrapper">
                <div className="row align-items-center mb-1">
                    <div className="col-6 pe-0">
                        <p className="font-12 weight-700 mb-0">{t('total')} <span className="font-8 color-grey weight-400">{t('incl_tax')} ({incTax}%)</span></p>
                    </div>
                    <div className="col-6 text-end ps-0">
                        <p className="font-12 weight-900 color-orange mb-0">{total.toLocaleString()} {t('thb')}</p>
                    </div>
                </div>
                <div className="row align-items-center">
                    {/* {routePath == "" && (
                        <>
                            <div className="col-4 ">
                                <a href={baseLink + "/ticket/" + packageCode + "/" + (currentStep + 1)}><button className="btn btn-blue-nextbuttom" type="button">{t('next')}</button></a>
                            </div>

                            <div className="col-1 px-0 text-center">
                                <p className="font-12 color-grey mb-0">{t('or')}</p>
                            </div>
                        </>
                    )} */}

                    {/* routePath == "" ? "col-6 text-end" : "col-12 text-end" */}
                    <div className={"col-12 text-end"}>
                        <a href={baseLink + routePath + "/ticket/" + packageCode + "/5"}><button className="btn btn-main" type="button">{t('gotocheckout')}</button></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MiniCart;