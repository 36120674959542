import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import noImg from '../component/img/no_image.png';
import { ImageLoader } from "react-image-and-background-image-fade";
import { useGlobalState } from '../global'
import { PackageCard4Buy } from './PackageCard4Buy';
import { PackageCard4BuyFlip } from './PackageCard4BuyFlip';
import CalVatExclude from '../customHook/CalVatExclude';

//Image//
export const PackageCard4 = (props) => {

    let myCart = sessionStorage.getItem('MyCart');
    myCart = JSON.parse(myCart);

    const { packageCard, pictureSize, index, PackageItemlength, packageList, isflip, apiLink, baseLink, packageTicket, subTicket, language } = props

    const [routePath, setRoutePath] = useGlobalState('route');
    const [businessPolicy] = useGlobalState('businessPolicy');
    const [picturePath, setPicturePath] = useState()
    const [flip, setFlip] = useState(false)
    const [netPrice, setNetPrice] = useState(0)
    const [numGuest, setNumGuest] = useState(0)
    const [saveWord, setSaveword] = useState('')
    const [selectedI, setSelectedI] = useState('');
    const [editI, setEditI] = useState(false);
    const [itemQty, setItemQty] = useState(1);
    const [ItemlistSort, setItemlistSort] = useState([])
    const [requiredDate, setRequiredDate] = useState("")
    const [selectedDate, setSelectedDate] = useState(null)
    const [policyVat, setPolicyVat] = useState(true)
    // const [vat, setVat] = useState(0)

    useEffect(() => {
        if (packageCard.NoOfPax == 2) {
            setSaveword('duos')
        } else if (packageCard.NoOfPax > 2) {
            setSaveword('groups')
        } else {
            setSaveword('one')
        }

        const sort = packageCard.PackageItemLists.sort((a, b) => (a.ItemType > b.ItemType ? -1 : 1))
        setItemlistSort(sort)
        if (packageCard.PackagePictureLists.length > 0) {
            let index = packageCard.PackagePictureLists.findIndex(x => x.PictureSize == pictureSize)
            if (index > -1) {
                setPicturePath(packageCard.PackagePictureLists[index].PictureUrl)
            } else {
                setPicturePath(noImg)
            }
        } else {
            setPicturePath(noImg)
        }

        if (packageCard.IsDiscount) {
            let netP = packageCard.PackagePrice - packageCard.DiscountPrice
            setNetPrice(netP)
        } else setNetPrice(packageCard.PackagePrice)

        if (packageCard.PackageGuestLists.length > 0) {
            setNumGuest(packageCard.PackageGuestLists.length)
        }

        if (myCart != null) {
            const pIndex = myCart.findIndex((e) => {
                return e.PackageCode === packageCard.PackageCode;
            });

            if (pIndex !== -1) {
                // ถ้ามี Package อยู่ใน Cart
                setItemQty(myCart[pIndex].Qty);
                setSelectedDate(myCart[pIndex].VisitDate)
                // setSelectedLocation(myCart[pIndex].VisitLocation)
                // เช็คว่า Package อยู่ใน Mode Edit หรือไม่
                if (myCart[pIndex].status === 'edit') {
                    setSelectedI('');
                    setEditI(true);
                } else setSelectedI('hidden');
            } else {
                // ถ้าไม่มี Package อยู่ใน Cart
                setSelectedI('');
                setEditI(false);
            }
            console.log("setItemQty", itemQty)
        }
        console.log("businessPolicy", businessPolicy);
        // if (businessPolicy) {
        //     if (!businessPolicy.IsIncludeVat) calVat()
        // }
        CalVatExclude(packageCard, businessPolicy)
    }, [packageCard])

    // const calVat = () => {
    //     const vatPrice = packageCard.PackagePrice * (businessPolicy.VatRate / (100 + businessPolicy.VatRate))
    //     const vatDiscount = packageCard.DiscountPrice * (businessPolicy.VatRate / (100 + businessPolicy.VatRate))
    //     packageCard.PackagePriceExVat = (packageCard.PackagePrice - vatPrice)
    //     packageCard.DiscountPriceExVat = (packageCard.DiscountPrice - vatDiscount)
    //     packageCard.isExCludeVat = !businessPolicy.IsIncludeVat
    // }


    const addToCart = (item, isEdit) => {
        console.log("EditCart => ", isEdit, " item: ", item);
        props.onAddToCart(item, isEdit)
    }





    // ภาษา
    const { t } = useTranslation();

    return (
        <>
            <div className={index < 2 ? "landingpage-small" : "landingpage"} style={{ width: index < 2 ? '50%' : '' }}>
                <div className="col-lg-12 mb-4 d-flex align-items-stretch" style={{ justifyContent: 'center', alignItems: 'center', border: '1px solid #e0e0e0', borderRadius: '5px', height: index < 2 ? '303px' : '' }}>
                    <div className={index < 2 ? "card card-hottest border-0" : "card card-hottest-large border-0"} style={{ display: 'contents' }}>
                        <div className="card-hottest-left" style={{ width: index < 2 ? '50%' : '70%' }}>
                            <ImageLoader src={picturePath} transitionTime='0s'>
                                {({ hasLoaded, shouldShowLoader, hasFailed, src }) => (
                                    <>
                                        {shouldShowLoader && !hasFailed && (
                                            <div className="card-img-top square" style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <h2 style={{ color: 'rgb(112 112 112)', fontSize: '20px' }}> Loading ... </h2>
                                            </div>
                                        )}
                                        {hasLoaded && (
                                            <>
                                                <img src={picturePath} className="card-img-top square" style={{ height: '100%' }} alt="Card Image" />
                                            </>
                                        )}
                                    </>
                                )}
                            </ImageLoader>

                            <div className="font-13 text-right weight-700">
                                {numGuest > 0 && (
                                    <div id="adults">
                                        <span className="adults" >
                                            <img src={packageCard.GuestInTicketIcon} style={{ display: "inline", paddingRight: 5 }} alt="" />
                                            {packageCard.NoOfPax} {t('pax')}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {!flip ? (
                            <PackageCard4Buy
                                language={language}
                                subTicket={subTicket}
                                packageCard={packageCard}
                                packageList={packageList}
                                netPrice={netPrice}
                                PackageItemlength={PackageItemlength}
                                selectedDate={selectedDate}
                                itemQty={itemQty}
                                index={index}
                                saveWord={saveWord}
                                routePath={routePath}
                                isflip={isflip}
                                apiLink={apiLink}
                                baseLink={baseLink}
                                packageTicket={packageTicket}
                                addToCart={addToCart}
                                setRequiredDate={setRequiredDate}
                                setItemQty={setItemQty}
                            />
                        )
                            :
                            (
                                <PackageCard4BuyFlip
                                    packageCard={packageCard}
                                    packageList={packageList}
                                    netPrice={netPrice}
                                    PackageItemlength={PackageItemlength}
                                    selectedDate={selectedDate}
                                    itemQty={itemQty}
                                    index={index}
                                    saveWord={saveWord}
                                    routePath={routePath}
                                    isflip={isflip}
                                    apiLink={apiLink}
                                    baseLink={baseLink}
                                    packageTicket={packageTicket}
                                    addToCart={addToCart}
                                    setRequiredDate={setRequiredDate}
                                    setSelectedDate={setSelectedDate}
                                    setItemQty={setItemQty}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
