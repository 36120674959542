import React, {useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom';
import { gapi } from 'gapi-script';
import { useTranslation } from "react-i18next";

//Components
import GGLogin from "../component/GGLogin";
import FBLogin from "../component/FBLogin";

import useRegister from '../customHook/useRegister';
import { apiLink, clientId, coreApi } from '../constants/DataPublic'


const VisitorInfo = (props) => {

    const {language} = props
    const navigate = useNavigate();
    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    const [guestInfo, setGuestInfo] = useState(user)
    const [password, setPassword] = useState("")
    const [cfpassword, setCFPassword] = useState("")
    const [message, setMessage] = useState("")
    let {country, province, countryPhone, getCountry, getProvince, getCountryPhone} = useRegister();

    useEffect(()=>{
        console.log("Visitor Info: ", guestInfo);
        if (user != null && user.isLogin){
            navigate("/done")
        } else {
            props.getCartItem(0)
            getCountry()
            getCountryPhone()
            if (guestInfo.countryId != null ){
                getProvince(guestInfo.countryId)
            }
        }
    },[])

    const doItLater = (e) => {
        e.preventDefault()
        guestInfo.guestDone = true
        sessionStorage.setItem('UserLogin', JSON.stringify(guestInfo))
        navigate("/done")
    }

    const saveVisitorInfo = async (e) => {
        e.preventDefault()
        if (password !== cfpassword){
            setMessage("Password not match, please try again")
        } else {
            guestInfo.password = password
            sessionStorage.setItem('UserLogin', JSON.stringify(guestInfo))
            const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                "UserId": guestInfo.userId,
                "UserCode": guestInfo.userCode,
                "PhoneNo": guestInfo.phoneNo,
                "Password": guestInfo.password,
                "CountryId": guestInfo.countryId,
                "ProvinceId": guestInfo.provinceId,
                "HasSubscribeOffer": false,
                "FirstName": guestInfo.fName,
                "LastName": guestInfo.lName,
                "Email": guestInfo.email,
                "IDCardNo": guestInfo.idCardNo
            }),
            };

            await fetch(
                coreApi+"/afcl/Account/visitor/update/profile",
            requestOptions
            )
            .then(function(response) {
                return response.json();
            })
            .then(function(data) {
                const items = data;
                if (items["Success"] === true) {
                    console.log("Success = true");
                    navigate("/")
                } else { 
                    console.log("Success = false");
                }
            })
            .catch((error) => {
                console.log("Error: ", error);
                // alert('Error: API /afcl/Account/visitor/update/profile ' , error)
            });
        }
    }

    // Login 

    const [ggUser,setGGUser] = useState({})
    const [fbUser,setFBUser] = useState({})
    const [needRegist, setNeedRegist] = useState(false)

    const getGGUser = (ggUser) =>{
        setGGUser(ggUser)
        props.getGGUser(ggUser)
    }

    const getFBUser = (fbUser) =>{
        setFBUser(fbUser)
        props.getFBUser(fbUser)
    }

    const getNeedRegist = (needRegist) => {
        setNeedRegist(needRegist)
      }
    
      useEffect(() => {
        if (needRegist){
          navigate('/signup')
        }
      }, [needRegist])

    // Login with Google
    useEffect(() => {
        function start(){
        gapi.client.init({
            clientId: clientId,
            scope : ""
        })
        }
        gapi.load('client:auth2', start);
    },[]);

    const setUserCode = (userCode) => {
        props.setUserCode(userCode)
      }

    // ภาษา
    const { t } = useTranslation();
       
  return (
    <div className="page page-steps">
    <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 py-5" style={{margin: "0 auto"}}>

		<div className="d-flex justify-content-center">
			<div className="text-center pt-5 ">
				<h2 className="font-40 color-main mb-2">{t('create_account')}<br/>{t('get_reward')}</h2>
				<p className="mb-5">{t('visitor_info_sub')}</p>
			</div>
		</div>

        <div className="row">
            <div className="col-xl-9 col-lg-9 col-md-10 col-sm-12" style={{margin: "0 auto"}}>
                <h2 className="font-30 text-uppercase mb-1 lh-1 mb-5">{t('visitor_information')}</h2>

                <form onSubmit={saveVisitorInfo}>
                    <div className="row mb-3">
                        {/* Start form lefr */}
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">          
                            
                            <div className="border-right-separator">
                                <div className="col-11 mb-4">
                                    <p className="color-grey font-12 mb-0">{t('firstname')} : </p>
                                    <p className="weight-700 mb-0">{guestInfo.fName}</p>
                                </div>

                                <div className="col-11 mb-4">
                                    <p className="color-grey font-12 mb-0">{t('lastname')} : </p>
                                    <p className="weight-700 mb-0">{guestInfo.lName}</p>
                                </div>

                                <div className="col-11 mb-5">
                                    <p className="color-grey font-12 mb-0">{t('id_number')} :</p>
                                    <p className="weight-700 mb-0 ">{guestInfo.idCardNo}</p>
                                </div>
                                            
                                    
                                <div className="mb-3 row col-11">
                                    <label htmlFor="phone-accountinfo" className="text-pink">{t('phone_number')}</label>
                                    <div className="row">
                                    <div className="col-3 pb-3 pb-lg-0">
                                        <select className="form-control" id="telCode" defaultValue={guestInfo.telCode} onChange={e => guestInfo.telCode = e.target.value}>
                                        <option key='0' value='0'>-</option>
                                        {
                                            countryPhone.map((e,key) => {
                                            return <option key={e.CountryId} value={e.CountryId}>{e.PhoneCode}</option>
                                            })
                                        }
                                        </select>
                                    </div>
                                    <div className="col-9">
                                    <input type="tel" id="phone-accountinfo" className="form-control" name="phone" defaultValue={guestInfo.phoneNo} title="Mobile Number should contain numbers only" maxLength="10" 
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                            }
                                        }} required/>
                                    </div>
                                    </div>
                                </div>

                                <div className="mb-3 row col-11">
                                    <label htmlFor="pass-accountinfo" className="text-pink">{t('password')+"*"}</label>
                                    <input type="password" id="pass-accountinfo" className="form-control" name="pass" required minLength="6" maxLength="6" 
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                    }}
                                    onChange={e => setPassword(e.target.value)}/>
                                </div>

                                <div className="mb-3 row col-11">
                                    <label htmlFor="retypepass-accountinfo" className="text-pink">{t('retry_password')+"*"}</label>
                                    <input type="password" id="retypepass-accountinfo" className="form-control" name="retypepass" required minLength="6" maxLength="6" 
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                        }
                                    }}
                                    onChange={e => setCFPassword(e.target.value)}/>
                                    <span className="text-danger">{message}</span>
                                </div>
                                                        
                            </div>
                        </div>
                        {/* End form lefr */}

                        {/* Start form right */}
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="align-items-stretch">

                                <div className="mb-4 col-lg-11">
                                    <p className="color-grey font-12 mb-0">{t('email')}: </p>
                                    <p className="weight-700 mb-0 ">{guestInfo.email}</p>
                                </div>

                                <div className="col-11 form-group mb-3">
                                    <label htmlFor="inputCountry" className="text-pink">{t('country')}</label>
                                    <select className="form-control" id="inputCountry" value={guestInfo.countryId} onChange={e => {
                                        getProvince(e.target.value)
                                        guestInfo.countryId = e.target.value
                                        }}>
                                        <option key="0" value="0">{t('select_country')}</option>
                                        {
                                            country.map((e,key) => {
                                            return <option key={e.CountryId} value={e.CountryId}>{e.CountryName}</option>
                                            })
                                        }
                                    </select>
                                </div>

                                <div className="col-11 form-group mb-5">
                                    <label htmlFor="inputProvince" className="text-pink">{t('state')}</label>
                                    <select id="inputProvince" className="form-control" value={guestInfo.provinceId} >
                                    <option key="0" value="0">{t('select_province')}</option>
                                    {
                                        province.map((e,key) => {
                                        return <option key={e.CityId} value={e.CityId}>{e.CityName}</option>
                                        })
                                    }
                                    </select>
                                </div>

                                <div className="form-check mb-5">
                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" onChange={e => guestInfo.hasSubscribeOffer = e.target.value}/>
                                    <label className="form-check-label" htmlFor="exampleRadios1">{t('subscribe')}</label>
                                </div>

                                <p className="text-pink">* {t('indicate_required')}</p>     
                                        
                            </div>
                        </div>
                        {/* End form right */}

                    </div>

                    <div className="mb-3 row align-items-center">									
                        <div className="col-12 relative">
                            <hr/>
                            <span className="or bg-white">or</span>
                        </div>									
                    </div>

                    <div className="row" style={{justifyContent: "center"}}>									
                        <div className="col-xl-10 col-lg-10 col-md-6 col-sm-12">
                            <div className="row">
                                {/* <div className="mb-3 gustloginFB">
                                    <FBLogin language={language} fbtext={'facebook'} getFBUser={getFBUser} getNeedRegist={getNeedRegist} setUserCode={setUserCode}/>
                                </div> */}
                                <div className="mb-5 gustloginGG">
                                    <GGLogin language={language} ggtext={'google'} getGGUser={getGGUser} getNeedRegist={getNeedRegist} setUserCode={setUserCode}/>
                                </div>
                            </div>	
                        </div>									
                    </div>

                    <hr className="mb-5"/>

                    <div className="row btn-right">
                        <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                                    <button type="button" className="btn btn-default" onClick={doItLater}>{t('do_later')}</button>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3">
                                    <button type="submit" className="btn btn-accountinfo">{t('save')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </form>
            </div>
            
        </div>

	</div>
    </div>
  )
}
export default VisitorInfo