import FacebookLogin from 'react-facebook-login';
import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import FBIcon from "../component/img/facebook.png";

import "../component/css/style.css";
import "../component/css/custom.css";
import { appId, apiLink, coreApi } from '../constants/DataPublic'

const FBLogin = (props) =>{

    const { t } = useTranslation();

    const navigate = useNavigate();
    const [needRegist, setNeedRegist] = useState(false)
    const {language} = props
    const {fbtext} = props

    const componentClicked = () =>{
        console.log('clicked');
    }
                
    const responseFacebook = async (response) =>{
        console.log(response);
        if (response.status !== "unknown"){
            let fbUser = response;
            props.getFBUser(fbUser)
            console.log("props fbUser: ", fbUser)

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ 
                    "Provider":"Facebook",
                    "ProviderKey":response.userID,
                    "Email": response.email,
                    "PushKey":"",
                    "DeviceType":"",
                    "DeviceKey":""
                    }),
            };
            console.log("CheckSocialLogin : ", requestOptions)
            await fetch(
                coreApi + "/afcl/Account/external/checklogin",
                requestOptions
            )
            .then(function(response) {
                return response.json();
            })
            .then(function(data) {
                const items = data;
                console.log(items);
                console.log("NeedRegister: ", items["Result"].NeedRegister);
                if(!items["Success"] && items["Result"].NeedRegister){
                    setNeedRegist(true)
                    props.getNeedRegist(items["Result"].NeedRegister)
                } else if (items["Success"] && !items["Result"].NeedRegister) {
                    props.setUserCode(items["Result"].User.UserCode)
                    let userLogin = {
                    fName: items["Result"].User.FirstName,
                    lName: items["Result"].User.LastName,
                    email: items["Result"].User.Email,
                    userId: items["Result"].User.UserId,
                    userCode: items["Result"].User.UserCode,
                    photo: items["Result"].User.UserPicture,
                    isLogin: true,
                    channelCode: items["Result"].ChannelCode
                    }
                    sessionStorage.setItem('UserLogin',JSON.stringify(userLogin))
                    navigate("/")
                    props.getNeedRegist(items["Result"].NeedRegister)
                } 
                
            })
            .catch((error) => {
            console.log("Error: ", error);
            // alert('Error: API /afcl/Account/external/checklogin ', error)
            });
        }
    }

    return (
            <div>
                <FacebookLogin
                appId= {appId}
                autoLoad={false}
                cssClass="btn btn-link btnloginsocial btnloginsocial-guest"
                fields="name,email,picture"
                icon={<img src={FBIcon} alt="" className="picloginsocial"/>}
                textButton={<span className="textloginsocial">{t(fbtext)}</span>}
                onClick={componentClicked}
                callback={responseFacebook}
                disableMobileRedirect={true}
                isMobile={false}
                />
            </div>
    )
    
}

export default FBLogin