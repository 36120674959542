import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import TermIcon from "../../component/img/term.png";
import { SubTicket } from './SubTicket';
import { baseLink, apiLink } from '../../constants/DataPublic'

const Step2 = (props) => {

  let myCart = sessionStorage.getItem('MyCart');
  myCart = JSON.parse(myCart);

  const { packageCode } = props
  const { currentStep } = props
  const { language } = props
  const { cartUpdate } = props
  const { itemList } = props

  // const [itemList, setItemList] = useState([])
  const [packageSelected, setPackageSelected] = useState({})

  useEffect(() => {
    getPackageCard()
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  // useEffect(() => {
  //   console.log('itemList', itemList);
  //   let pk = itemList.filter((a) => {
  //     return myCart.find((b) => {
  //       return a.PackageCode === b.PackageCode;
  //     });
  //   });

  //   pk.forEach((a) => {
  //     myCart.forEach((b) => {
  //       if (a.PackageCode === b.PackageCode) {
  //         b.PackageName = a.PackageName;
  //       }
  //     });
  //   });

  //   sessionStorage.setItem('MyCart', JSON.stringify(myCart));
  //   props.setCart(myCart);

  // }, [itemList]);

  const getPackageCard = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language }
    };
    const res = await fetch(apiLink + "/afcl/Package/MoreThill", requestOptions);
    res
      .json()
      .then((res) => {
        console.log("Step2: ", res["Result"]["Packages"])
        const packageAll = res["Result"]["Packages"]
        let sPackage = packageAll.filter(e => e.PackageCode === packageCode)[0]
        setPackageSelected(sPackage)

        console.log('sPackage', sPackage)
        console.log('packageAll', packageAll)
      })
      .catch((error) => {
        console.log(error);
        // alert('Error: API /afcl/Package/MoreThill/ ', error)
      });
  }

  const onAddToCart = (itemCart, isEdit) => {
    props.onAddToCart(itemCart, isEdit)
  }

  // ภาษา
  const { t } = useTranslation();

  return (
    <div className="col-main">
      <div className="right-wrapper scrollable">
        <div className="d-none d-lg-block">
          <div className="col-sm-12 text-end mb-2">
            <button className="btn btn-blue mb-3 btn-skip" type="button" onClick={() => props.setCurrentStep(3)}>{t('skip')}</button>
          </div>
        </div>
        {packageSelected != null && Object.keys(packageSelected).length > 0 && (
          <SubTicket flip={true} itemList={packageSelected} currentStep={currentStep} cartUpdate={cartUpdate} onAddToCart={onAddToCart} />
        )}
        {itemList.map((e, index) => {
          let selectedPackageCode = ""
          if (packageSelected != null) {
            selectedPackageCode = packageSelected.PackageCode
            console.log("PackageSelected = ", selectedPackageCode);
          }
          if (packageSelected == null || e.PackageCode != selectedPackageCode) {
            return <SubTicket key={index} flip={true} currentStep={currentStep} cartUpdate={cartUpdate} itemList={e} onAddToCart={onAddToCart} />
          }
        })
        }
      </div>
    </div>
  )
}
export default Step2;