import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button as ButtonUI, Box } from '@material-ui/core';

//Image
import logo from '../component/img/logo-xl.png';
import PicProfile from '../component/img/picuser2.png';
import { ReactComponent as Add } from '../component/img/Add.svg';
import { ReactComponent as Del } from '../component/img/Del.svg';

//css
import '../component/css/style.css';
import '../component/css/custom.css';

import useRegister from '../customHook/useRegister';
import { baseLink } from '../constants/DataPublic';
import { useGlobalState } from '../global'

const Signup = (props) => {
  const navigate = useNavigate();
  const param = useLocation();

  // Check User Register
  let { canRegist, message, email, country, countryPhone, userRegist, setCanRegist, setEmail, getCountry, getCountryPhone, checkSignup, updateUserInfo } = useRegister();

  const [routePath, setRoutePath] = useGlobalState('route');
  const [userSignup, setUserSignup] = useState({ fName: '', lName: '', tel: '', lineID: '', countryCode: '', photo: '', email: '', passcode: '', provider: '', telCode: '' });
  const [requireFname, setRequireFname] = useState('');
  const [requireLname, setRequireLname] = useState('');
  const [requireTel, setRequireTel] = useState('');

  // Select Photo
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [base64code, setBase64code] = useState('');

  let user = sessionStorage.getItem('UserLogin');
  user = JSON.parse(user);

  // Google, Facebook User
  const { ggUser, fbUser } = props;

  useEffect(() => {
    if (user != null && user.isLogin) {
      navigate('/');
    }

    console.log("SignUpGgUser", ggUser)

    if (Object.keys(ggUser).length > 0) {
      setCanRegist(true);
      userSignup.email = ggUser.email;
      userSignup.fName = ggUser.givenName;
      userSignup.lName = ggUser.familyName;
      userSignup.provider = 'Google';
      userSignup.providerKey = ggUser.sub;
    }

    if (Object.keys(fbUser).length > 0) {
      setCanRegist(true);
      userSignup.email = fbUser.email;
      userSignup.fName = fbUser.name.split(' ')[0];
      userSignup.lName = fbUser.name.split(' ')[1];
      userSignup.provider = 'Facebook';
      userSignup.providerKey = fbUser.userID;
    }

    getCountry();
    getCountryPhone();
    userSignup.telCode = 66;
    if (param.state) {
      userSignup.IsAcceptPolicy = param.state.IsAcceptPolicy;
      userSignup.IsAcceptMarketing = param.state.IsAcceptMarketing;
    }
  }, []);

  function convertImgToBase64(url, callback, outputFormat) {
    var canvas = document.createElement('CANVAS');
    var ctx = canvas.getContext('2d');
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL(outputFormat || 'image/png');
      callback.call(this, dataURL);
      // Clean up
      canvas = null;
    };
    img.src = url;
  }

  const inputEmail = (e) => {
    setEmail(e.target.value);
    userSignup.email = e.target.value;
  };

  const onChangeFile = (e) => {
    const targetFile = e.target.files[0];
    if ((targetFile != undefined) & (targetFile != null)) {
      setSelectedImage(targetFile);
      const files = e.target.files;
      const file = files[0];
      getbase64(file);
    }
  };

  const getbase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setBase64code(reader.result);
      userSignup.photo = reader.result.split(',')[1];
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  };

  useEffect(() => {
    if (selectedImage != null && selectedImage != 'delete') {
      setImageUrl(URL.createObjectURL(selectedImage));
    } else if (ggUser.imageUrl != null && ggUser.imageUrl != '' && selectedImage != 'delete') {
      setImageUrl(ggUser.imageUrl);
      convertImgToBase64(ggUser.imageUrl, function (base64Img) {
        userSignup.photo = base64Img.split(',')[1];
        console.log('ImgBase64', base64Img.split(',')[1]);
      });
    } else if (fbUser.picture != null && fbUser.picture.data.url != '' && selectedImage != 'delete') {
      setImageUrl(fbUser.picture.data.url);
      convertImgToBase64(fbUser.picture.data.url, function (base64Img) {
        userSignup.photo = base64Img.split(',')[1];
        console.log('ImgBase64', base64Img.split(',')[1]);
      });
    } else {
      setImageUrl(PicProfile);
    }
    console.log('UseEffect : ', userSignup);
  }, [selectedImage, userSignup]);

  // Confirm Signup
  const confirmSignup = (e) => {
    e.preventDefault();
    console.log('ConfirmSignup : ', userSignup);
    if (userSignup.fName === '' || userSignup.fName == undefined) {
      setRequireFname('warn_firstname');
    } else setRequireFname('');
    if (userSignup.lName === '' || userSignup.lName == undefined) {
      setRequireLname('warn_lastname');
    } else setRequireLname('');
    if (userSignup.tel === '') {
      setRequireTel('warn_phone');
    } else setRequireTel('');
    if (userSignup.fName != '' && userSignup.lName != '' && userSignup.tel != '') {
      props.getUserSignup(userSignup);
      navigate('/setpw');
    }
  };

  // ภาษา
  const { t } = useTranslation();

  return (
    <div>
      <div className="page box-mh">
        <div className="section py-5">
          <div className="container">
            <div className="row">
              <div className="logosignin">
                <img src={logo} id="logosignin" />
              </div>

              <div className="col-xl-5 col-lg-6 col-md-7 col-sm-12 box-body box-border box-signin signinradius" style={{ margin: '0 auto' }}>
                <form>
                  <div className="mb-3">
                    <h2 className="main-title mb-3">{t('confrim_your_email')}</h2>
                    <label htmlFor="exampleFormControlInput1" className="form-label text-color">
                      {' '}
                      {t('email')}*{' '}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      value={userSignup.email}
                      id="exampleFormControlInput1"
                      placeholder="name@example.com"
                      onChange={(e) => inputEmail(e)}
                      required
                      disabled={canRegist}
                    />
                    <span className="text-danger">{t(message)}</span>
                  </div>

                  <div className="mb-3">
                    <Button type="button" className="btn btn-link btn-lb text-capitalize" onClick={checkSignup} disabled={canRegist}>
                      {t('signup')}
                    </Button>
                  </div>
                </form>

                <form onSubmit={confirmSignup}>
                  {canRegist && (
                    <fieldset>
                      <div className="mb-3">
                        <h2 className="main-title mb-3">{t('create_a_new_account')}</h2>
                        <p className="weight-500">
                          {t('already_have_one')}? <a href={baseLink + routePath + '/signin'}>{t('signin')}</a>
                        </p>

                        <div className="form-group mb-3">
                          <label htmlFor="exampleFormControlInput1" className="text-color">
                            {t('firstname')}*
                          </label>
                          <input type="text" className="form-control" id="fName" defaultValue={userSignup.fName} placeholder={t('firstname')} onChange={(e) => (userSignup.fName = e.target.value)} />
                          <span className="text-danger">{t(requireFname)}</span>
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="exampleFormControlInput1" className="text-color">
                            {t('lastname')}*
                          </label>
                          <input type="text" className="form-control" id="lName" defaultValue={userSignup.lName} placeholder={t('lastname')} onChange={(e) => (userSignup.lName = e.target.value)} />
                          <span className="text-danger">{t(requireLname)}</span>
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="exampleFormControlInput1" className="text-color">
                            {t('telephone')}*
                          </label>
                          <div className="row">
                            <div className="col-3 pb-3 pb-lg-0">
                              <select className="form-control" id="telCode" defaultValue={userSignup.telCode} onChange={(e) => (userSignup.telCode = e.target.value)}>
                                <option key="0" value="0">
                                  -
                                </option>
                                {countryPhone.map((e, key) => {
                                  return (
                                    <option key={e.CountryId} value={e.CountryId} selected={e.CountryId == userSignup.telCode}>
                                      {e.PhoneCode}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="col-9">
                              <input
                                type="tel"
                                className="form-control"
                                id="tel"
                                placeholder={t('telephone')}
                                maxLength="10"
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={(e) => (userSignup.tel = e.target.value)}
                              />
                            </div>
                            <span className="text-danger">{t(requireTel)}</span>
                          </div>
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="exampleFormControlInput1" className="text-color">
                            {t('lineid')}
                          </label>
                          <input type="text" className="form-control" id="lineID" placeholder={t('lineid')} onChange={(e) => (userSignup.lineID = e.target.value)} />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="exampleFormControlSelect1" className="text-color">
                            {t('country_of_residence')}
                          </label>
                          <select className="form-control" id="country" onChange={(e) => (userSignup.countryCode = e.target.value)}>
                            <option key="0" value="0">
                              -
                            </option>
                            {country.map((e, key) => {
                              return (
                                <option key={e.CountryId} value={e.CountryId}>
                                  {e.CountryName}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="exampleFormControlSelect1" className="text-color">
                            {t('photo')}
                          </label>
                          <div className="box-profile box-border">
                            <div className="row">
                              <div className="col-9 text-center">
                                <img className="profilepreview" src={imageUrl} />
                              </div>
                              <div className="col-3 box-objet-picupload ">
                                <div>
                                  <input accept="image/*" type="file" id="select-image" style={{ display: 'none' }} onChange={onChangeFile} />
                                  <label htmlFor="select-image">
                                    <ButtonUI color="primary" component="span" className="mb-3 objet-picupload">
                                      <Add />
                                    </ButtonUI>
                                  </label>
                                </div>
                                <div>
                                  <ButtonUI
                                    color="primary"
                                    component="span"
                                    className="mb-3 objet-picupload"
                                    onClick={() => {
                                      document.getElementById('select-image').value = '';
                                      setSelectedImage('delete');
                                      userSignup.photo = '';
                                    }}
                                  >
                                    <Del />
                                  </ButtonUI>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <Button type="submit" className="btn btn-link btn-db text-capitalize">
                          {t('confirm')}
                        </Button>
                      </div>
                    </fieldset>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Signup;
